import { Injectable } from '@angular/core';
import {
  MAX_SAVED_IDS,
  LOCAL_LOGIN_IDS_KEY,
} from '@chiron/app/shared/util/constants';
import { LocalLoginId } from '../state/local-login-ids/local-login-ids.reducer';

@Injectable({
  providedIn: 'root',
})
export class LoginIdService {
  getLoginIds(): LocalLoginId[] {
    const loginIds = localStorage.getItem(LOCAL_LOGIN_IDS_KEY);
    return loginIds ? JSON.parse(loginIds) : [];
  }
  addLoginId(existingIds: LocalLoginId[], username: string) {
    const idExists = existingIds.find((u) => u.username === username);
    const loginId: LocalLoginId = {
      username: username,
      timestamp: new Date().toISOString(),
    };
    if (!idExists) {
      existingIds = [...existingIds, loginId];
      if (existingIds.length > MAX_SAVED_IDS) {
        existingIds.shift();
      }
    }
    return existingIds;
  }
  removeLoginId(existingIds: LocalLoginId[], username: string) {
    existingIds = existingIds.filter((id) => id.username !== username);
    return existingIds;
  }
}
