import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'config/environments/environment';

export const bpnRedirectionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  window.location.href = `${environment.gatewayUrl}/bpn${state.url}`;
  return false;
};
