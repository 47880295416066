import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { LocalLoginIdActions } from '../store-types';
import { LoginIdService } from '@frontdoor/app/services/login-id.service';
import { map, withLatestFrom } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectAll } from './local-login-ids.selectors';

@Injectable({ providedIn: 'root' })
export class LocalLoginIdsEffects {
  constructor(
    private actions$: Actions,
    private _loginIdService: LoginIdService,
    private _store: Store
  ) {}
  addLoginId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalLoginIdActions.addLoginId),
      withLatestFrom(this._store.pipe(select(selectAll))),
      map(([action, existingIds]) => {
        const loginIds = this._loginIdService.addLoginId(
          existingIds,
          action.username
        );
        return LocalLoginIdActions.updateLoginIdsSuccess({
          localLoginIds: loginIds,
        });
      })
    )
  );
  removeLoginId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalLoginIdActions.removeLoginId),
      withLatestFrom(this._store.pipe(select(selectAll))),
      map(([action, existingIds]) => {
        const loginIds = this._loginIdService.removeLoginId(
          existingIds,
          action.username
        );
        return LocalLoginIdActions.updateLoginIdsSuccess({
          localLoginIds: loginIds,
        });
      })
    )
  );
  loadLoginIds = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalLoginIdActions.getLoginIds),
      map(() => this._loginIdService.getLoginIds()),
      map((ids) =>
        LocalLoginIdActions.getLoginIdsSuccess({
          localLoginIds: ids,
        })
      )
    )
  );
}
