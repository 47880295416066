<mat-card class="main-container">
  <mat-card-header>
    <mat-card-title class="header-logo">
      <img src="/assets/images/logo.png" alt="biome-logo" />
    </mat-card-title>
    <mat-card-title>Forgot Password</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <br />
    <ng-container
      *ngIf="_resetPasswordService.resetPasswordStatus() as resetPasswordStatus"
    >
      <form
        (ngSubmit)="onSubmit()"
        #ngForgetPasswordForm="ngForm"
        *ngIf="resetPasswordStatus?.type !== 'success'"
      >
        <mat-form-field class="example-form-field">
          <input
            matInput
            [formControl]="username"
            type="text"
            placeholder="yourusername@biomedata.io"
          />
          <mat-error
            *ngIf="
              (username.touched || ngForgetPasswordForm.submitted) &&
              username.errors?.['email']
            "
            >Username must be an email.</mat-error
          >
          <mat-error
            *ngIf="
              (username.touched || ngForgetPasswordForm.submitted) &&
              username.errors?.['required']
            "
            >Username is required.</mat-error
          >
        </mat-form-field>
      </form>

      <div
        [ngClass]="{
          'auth-error': resetPasswordStatus.type === 'error',
          'auth-success': resetPasswordStatus.type === 'success'
        }"
      >
        {{ resetPasswordStatus.message }}
      </div>
      <br />
      <ng-container *ngIf="resetPasswordStatus.type !== 'success'">
        <button
          mat-raised-button
          data-ba-id="forgot-button"
          color="primary"
          (click)="onSubmit()"
        >
          Continue
        </button>
        <div class="info-box">
          Please enter your username followed by &#64;biomedata.io - if you are
          not sure what your username is please reach out to
          <a href="mailto:support@biome.io">support&#64;biome.io</a>.
        </div>
      </ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>
<ba-support-button />
