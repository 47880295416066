<mat-card class="main-container">
  <mat-card-header>
    <mat-card-title class="header-logo">
      <img src="/assets/images/logo.png" alt="biome-logo" />
    </mat-card-title>
    <mat-card-title>Forgot Username</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <br />
    <ng-container
      *ngIf="
        _forgotUsernameService.resetUsernameStatus() as resetUsernameStatus
      "
    >
      <form
        (ngSubmit)="onSubmit()"
        #ngForgetUsernameForm="ngForm"
        *ngIf="
          resetUsernameStatus?.type !== 'success' &&
          resetUsernameStatus?.type !== 'info'
        "
      >
        <mat-form-field class="example-form-field">
          <input
            matInput
            [formControl]="email"
            type="text"
            placeholder="youremail@biome.io"
          />
          <mat-error
            *ngIf="
              (email.touched || ngForgetUsernameForm.submitted) &&
              email.errors?.['email']
            "
            >Please enter a valid email address.</mat-error
          >
          <mat-error
            *ngIf="
              (email.touched || ngForgetUsernameForm.submitted) &&
              email.errors?.['required']
            "
            >Email is required.</mat-error
          >
        </mat-form-field>
      </form>
      <div
        [ngClass]="{
          'auth-error': resetUsernameStatus.type === 'error',
        }"
      >
        <span [innerHTML]="resetUsernameStatus.message"></span>
      </div>
      <br />
      <ng-container
        *ngIf="
          resetUsernameStatus.type !== 'success' &&
          resetUsernameStatus.type !== 'info'
        "
      >
        <button
          mat-raised-button
          data-ba-id="forgot-button"
          color="primary"
          (click)="onSubmit()"
        >
          Continue
        </button>
        <div class="info-box">
          Please enter your email address associated with your Biome account.
        </div>
      </ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>
<ba-support-button />
