import { Injectable, OnDestroy, signal } from '@angular/core';
import {
  ChangeRequesterPasswordGQL,
  ForgotPasswordGQL,
  ValidateUserGQL,
} from 'graphql/bifrost/generated';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService implements OnDestroy {
  private _subs = new SubSink();
  readonly resetPasswordStatus = signal<{
    type: 'error' | 'success' | null;
    message: string;
  }>({
    type: null,
    message: '',
  });
  constructor(
    private readonly _forgetPasswordMutation: ForgotPasswordGQL,
    private readonly _changePasswordMutation: ChangeRequesterPasswordGQL,
    private readonly _validateUserQuery: ValidateUserGQL
  ) {}

  requestForgetPassword(email: string) {
    return this._forgetPasswordMutation.mutate({ email }).pipe(
      map((res) => {
        if (res.data?.forgotPassword) {
          this.resetPasswordStatus.set({
            type: 'success',
            message:
              'An email with instructions to reset your password has been successfully sent to your provided email address. Please check your inbox shortly.',
          });
        }
      }),
      catchError((error) => {
        this.resetPasswordStatus.set({
          type: 'error',
          message: error,
        });
        return throwError(() => new Error(error));
      })
    );
  }
  changePassword(newPassword: string) {
    return this._changePasswordMutation.mutate({ newPassword }).pipe(
      map((res) => {
        if (!res.data?.changeRequesterPassword) {
          this.resetPasswordStatus.set({
            type: 'error',
            message: 'Error ! Password could not be updated.',
          });
        } else {
          this.resetPasswordStatus.set({
            type: 'success',
            message:
              'Your password has been changed successfully. Please note this may take up to 3 minutes to take effect. Meanwhile your old password may continue to work.',
          });
        }
      }),
      catchError((error) => {
        this.resetPasswordStatus.set({
          type: 'error',
          message: error.message,
        });
        return throwError(() => new Error(error));
      })
    );
  }
  validateUser(): Observable<boolean> {
    return this._validateUserQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.validateUser));
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
