import { createReducer, on } from '@ngrx/store';
import { LocalLoginIdActions } from '../store-types';
import { LOCAL_LOGIN_IDS_KEY } from '@chiron/app/shared/util/constants';

export const localLoginIdsFeatureKey = 'localLoginIds';
export interface LocalLoginId {
  username: string;
  timestamp: string;
}
export const initialState: LocalLoginId[] = [];
export const reducer = createReducer(
  initialState,
  on(LocalLoginIdActions.updateLoginIdsSuccess, (_, action) => {
    localStorage.setItem(
      LOCAL_LOGIN_IDS_KEY,
      JSON.stringify(action.localLoginIds)
    );
    return action.localLoginIds;
  }),
  on(
    LocalLoginIdActions.getLoginIdsSuccess,
    (_, action) => action.localLoginIds
  )
);
