import { createAction, props } from '@ngrx/store';
import { MaintenanceSlot } from './maintenance-slot.reducer';
import {
  CreateMaintenanceSlotInput,
  UpdateMaintenanceSlotInput,
} from 'graphql/bifrost/generated';
import { Update } from '@ngrx/entity';

export const loadMaintenanceSlots = createAction(
  '[Maintenance Slots List View] Load Maintenance Slots'
);
export const loadMaintenanceSlotsSuccess = createAction(
  '[Load Maintenance Slots Effect] Load Maintenance Slots Success',
  props<{ maintenanceSlots: MaintenanceSlot[] }>()
);
export const loadMaintenanceSlotsFailure = createAction(
  '[Load Maintenance Slots Effect] Load Maintenance Slots Failure',
  props<{ error: any }>()
);
export const loadMaintenanceSlot = createAction(
  '[Maintenance Slot Details View] Load Maintenance Slot',
  props<{ id: number }>()
);
export const loadMaintenanceSlotSuccess = createAction(
  '[Load Maintenance Slot Effect] Load Maintenance Slot Success',
  props<{ maintenanceSlot: MaintenanceSlot }>()
);
export const loadMaintenanceSlotFailure = createAction(
  '[Load Maintenance Slot Effect] Load Maintenance Slot Failure',
  props<{ error: any }>()
);
export const loadUpcomingMaintenanceSlot = createAction(
  '[BPN Homepage] Load Upcoming Maintenance Slot'
);
export const loadUpcomingMaintenanceSlotSuccess = createAction(
  '[Load Upcoming Maintenance Slot Effect] Load Upcoming Maintenance Slot Success',
  props<{ maintenanceSlot: MaintenanceSlot }>()
);
export const loadUpcomingMaintenanceSlotFailure = createAction(
  '[Load Upcoming Maintenance Slot Effect] Load Upcoming Maintenance Slot Failure',
  props<{ error: any }>()
);
export const createMaintenanceSlot = createAction(
  '[Maintenance Slot Details View] Create Maintenance Slot',
  props<{ input: CreateMaintenanceSlotInput }>()
);
export const createMaintenanceSlotSuccess = createAction(
  '[Create Maintenance Slot Effect] Create Maintenance Slot Success',
  props<{ maintenanceSlot: MaintenanceSlot }>()
);
export const createMaintenanceSlotFailure = createAction(
  '[Create Maintenance Slot Effect] Create Maintenance Slot Failure',
  props<{ error: any }>()
);
export const updateMaintenanceSlot = createAction(
  '[Maintenance Slot Details View] Update Maintenance Slot',
  props<{ input: UpdateMaintenanceSlotInput }>()
);
export const updateMaintenanceSlotSuccess = createAction(
  '[Update Maintenance Slot Effect] Update Maintenance Slot Success',
  props<{ maintenanceSlot: Update<MaintenanceSlot> }>()
);
export const updateMaintenanceSlotFailure = createAction(
  '[Update Maintenance Slot Effect] Update Maintenance Slot Success',
  props<{ error: any }>()
);
