import { Injectable } from '@angular/core';
import { ReplaySubject, map } from 'rxjs';

export interface BusyPayload {
  isBusy: boolean;
  message?: string;
}
const notBusyPayload: BusyPayload = { isBusy: false };

@Injectable({
  providedIn: 'root',
})
export class BusyBarService {
  private subject = new ReplaySubject<BusyPayload>();
  private busyCounter = 0;
  private readonly _state$ = this.subject.asObservable();
  readonly busy$ = this._state$.pipe(map((state) => state.isBusy));

  increment(message: string) {
    this.busyCounter++;
    const payload: BusyPayload = { isBusy: true, message };
    this.subject.next(payload);
  }

  decrement() {
    this.busyCounter--;
    if (this.busyCounter <= 0) {
      this.subject.next(notBusyPayload);
    }
  }
}
