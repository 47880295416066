import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SubSink } from 'subsink';
import { NgClass, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ForgotUsernameService } from '@frontdoor/app/services/forgot-username.service';
import { SupportButtonComponent } from '@shared/lib/components/support-button/support-button.component';
import { tap } from 'rxjs';

@Component({
  selector: 'ba-forgot-username',
  standalone: true,
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SupportButtonComponent,
  ],
})
export class ForgotUsernameComponent implements OnDestroy {
  private _subs = new SubSink();
  readonly email = this._fb.control<string>('', [
    Validators.required,
    Validators.email,
  ]);
  constructor(
    private readonly _fb: NonNullableFormBuilder,
    protected readonly _forgotUsernameService: ForgotUsernameService
  ) {}

  onSubmit() {
    if (this.email.valid) {
      this._subs.sink = this._forgotUsernameService
        .requestForgetUsername(this.email.value)
        .subscribe();
    }
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
