import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'config/environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ba-login-failed',
  template: `
    <div class="app-main-container">
      <div class="error-page-container">
        <mat-icon class="material-symbols-outlined">mood_bad</mat-icon>
        <div class="mat-body">
          <p *ngIf="errorMessage$ | async as error">{{ error }}</p>
          <p>
            Looks like we need some additional details to appropriately
            configure your account. Please
            <a [href]="supportDeskUrl" class="color-primary">click here</a> to
            submit a ticket to our support desk. Our team will review the
            request and follow up with your account administrator to finalize
            your access role.
          </p>
          <p>
            To go back to login, click
            <a href="/login" class="color-primary">here</a>.
          </p>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe, MatIconModule],
})
export class LoginFailedComponent {
  readonly supportDeskUrl = environment.supportDeskUrl;
  readonly errorMessage$ = this._route.queryParams.pipe(
    map((params) => params?.error)
  );
  constructor(private readonly _route: ActivatedRoute) {}
}
