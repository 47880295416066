import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromShared from './state/index';
import { EffectsModule } from '@ngrx/effects';
import { LocalAppStateEffects } from './state/local-app-state/local-app-state.effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    StoreModule.forFeature(fromShared.featureKey, fromShared.reducers, {
      metaReducers: fromShared.metaReducers,
    }),
    EffectsModule.forFeature(LocalAppStateEffects),
    MatSnackBarModule,
    MatDialogModule,
  ],
})
export class SharedLibModule {}
