import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterState } from '@ngrx/router-store';
import { environment } from 'config/environments/environment';
import * as fromPermissions from './permissions/permission.reducer';
import * as fromMaintenanceSlots from './maintenance-slot/maintenance-slot.reducer';
import * as fromLocalAppState from './local-app-state/local-app-state.reducer';

export const featureKey = 'shared-lib';

export interface SharedState {
  router: RouterState;
  [fromPermissions.permissionFeatureKey]: fromPermissions.PermissionState;
  [fromMaintenanceSlots.featureKey]: fromMaintenanceSlots.MaintenanceSlotsState;
  [fromLocalAppState.localAppStateFeatureKey]: fromLocalAppState.LocalAppState;
}

export const reducers: ActionReducerMap<SharedState> = {
  router: routerReducer,
  [fromPermissions.permissionFeatureKey]: fromPermissions.reducer,
  [fromMaintenanceSlots.featureKey]: fromMaintenanceSlots.reducer,
  [fromLocalAppState.localAppStateFeatureKey]: fromLocalAppState.reducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<SharedState>[] = !environment.production
  ? [logger]
  : [];
