import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LoginFailedComponent } from './components/login-failed.component';
import { bpnRedirectionGuard } from './guards/bpn-redirection.guard';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { canResetPasswordGuard } from './guards/reset-password.guard';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'change-password',
    canActivate: [canResetPasswordGuard],
    component: ChangePasswordComponent,
  },
  {
    path: 'configure',
    loadChildren: () => import('./configuration/configuration.routes'),
  },
  {
    path: '**',
    canActivate: [bpnRedirectionGuard],
    component: LoginFailedComponent,
  },
];
