<mat-card class="main-container">
  <mat-card-header>
    <mat-card-title class="header-logo">
      <img src="/assets/images/logo.png" alt="biome-logo" />
    </mat-card-title>
    <mat-card-title>Login to continue</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <br />
    <ng-container *ngIf="savedLoginIds$ | async as savedLoginIds">
      <ng-container *ngIf="savedLoginIds.length">
        <mat-card-subtitle>Select a previously used username</mat-card-subtitle>
        <mat-nav-list>
          <div class="flex-container" *ngFor="let loginId of savedLoginIds">
            <mat-list-item (click)="selectLoginId(loginId.username)">
              <mat-icon class="material-symbols-outlined" matListItemIcon
                >account_box</mat-icon
              >
              <span matListItemTitle class="title">{{ loginId.username }}</span>
            </mat-list-item>
            <button
              mat-icon-button
              (click)="removeLoginId(loginId.username)"
              matTooltip="Remove ID"
            >
              <mat-icon class="material-symbols-outlined">delete</mat-icon>
            </button>
          </div>
        </mat-nav-list>
        <mat-card-subtitle> OR enter a username here</mat-card-subtitle><br />
      </ng-container>
    </ng-container>
    <form (ngSubmit)="onSubmit()" #ngLoginForm="ngForm">
      <mat-form-field class="example-form-field">
        <input
          matInput
          [formControl]="username"
          type="text"
          placeholder="yourusername@biomedata.io"
        />
        <mat-error
          *ngIf="
            (username.touched || ngLoginForm.submitted) &&
            username.errors?.['email']
          "
          >Username must be an email.</mat-error
        >
        <mat-error
          *ngIf="
            (username.touched || ngLoginForm.submitted) &&
            username.errors?.['required']
          "
          >Username is required.</mat-error
        >
      </mat-form-field>
    </form>
    <div class="auth-error" *ngIf="authError()">
      {{ authError() }}
    </div>
    <br />
    <div class="login-actions">
      <div class="forgot-links">
        <div class="forgot-username color-primary">
          <a [routerLink]="['/forgot-username']">Forgot Username?</a>
        </div>
        <div class="forgot-password color-primary">
          <a [routerLink]="['/forgot-password']">Forgot Password?</a>
        </div>
      </div>
      <div class="login-button">
        <button
          mat-raised-button
          data-ba-id="login"
          color="primary"
          [disabled]="busy()"
          (click)="onSubmit()"
        >
          <mat-icon *ngIf="!busy()" class="material-symbols-outlined"
            >login</mat-icon
          >
          <div class="login-text-container">
            <mat-spinner diameter="20" *ngIf="busy()"></mat-spinner>
            <span class="login-text" *ngIf="!busy()">Continue </span>
            <span class="login-text" *ngIf="busy()">Logging in...</span>
          </div>
        </button>
      </div>
    </div>
    <div class="info-box">
      Please enter your username. For hospitals enrolled with single sign-on
      (SSO), this will be your hospital Cloud ID. For hospitals not yet enrolled
      with SSO, this will end in &#64;biomedata.io. If you are unsure of your
      username or if your site is enrolled with SSO, please reach out to:
      <a href="mailto:support@biome.io">support&#64;biome.io</a>.
    </div>
  </mat-card-content>
</mat-card>
<ba-support-button />
