import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'config/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { Store } from '@ngrx/store';
import { LocalAppStateActions } from '@shared/lib/state/store-types';
import { IJwtDto } from '@chiron/app/shared/dto/auth.dto';

enum TokenType {
  Access = 'baAccessToken',
  Refresh = 'baRefreshToken',
  Original = 'baOriginalToken',
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly _http = inject(HttpClient);
  private readonly _store = inject(Store);
  get accessToken(): string | null {
    return localStorage.getItem(TokenType.Access);
  }
  set accessToken(token: string | null) {
    if (token) {
      localStorage.setItem(TokenType.Access, token);
    }
  }
  get refreshToken(): string | null {
    return localStorage.getItem(TokenType.Refresh);
  }
  set refreshToken(token: string | null) {
    if (token) {
      localStorage.setItem(TokenType.Refresh, token);
    }
  }
  get username(): string | null {
    const token = this.accessToken;
    return token ? jwtDecode<IJwtDto>(token).sub : null;
  }
  constructor() {
    const url: URL = new URL(window.location.href);
    const jwtString = url.searchParams.get('jwt');
    if (url.searchParams.has('jwt')) {
      url.searchParams.delete('jwt');
      window.history.replaceState({}, '', url.href);
    }
    if (url.searchParams.has('SAMLResponse')) {
      url.searchParams.delete('SAMLResponse');
      window.history.replaceState({}, '', url.href);
    }
    this.initialize(jwtString);
  }
  initialize(jwtString: string | null) {
    const { pathname } = window.location;
    if (pathname.includes('login') || pathname.includes('forgot-password')) {
      return;
    }
    if (pathname.includes('change-password')) {
      this._destroySession();
      if (jwtString && this._isTokenValid(jwtString)) {
        this.accessToken = jwtString;
        this.refreshToken = jwtString;
      }
    } else {
      if (jwtString) {
        const jwt = JSON.parse(jwtString);
        this.accessToken = jwt.refreshToken;
        this.refreshToken = jwt.refreshToken;
      }
    }
    this._loadCurrent();
  }
  private _loadCurrent() {
    if (!this.isSessionValid()) {
      this.expireSession();
    }
  }
  public isSessionValid() {
    return this._isRefreshTokenValid();
  }
  private _isRefreshTokenValid(): boolean {
    return this._isTokenValid(localStorage.getItem(TokenType.Refresh));
  }
  private _isTokenValid(token: string | null): boolean {
    if (token) {
      try {
        const decodedToken = jwtDecode<IJwtDto>(token);
        return Date.now() < decodedToken.exp * 1000;
      } catch (err) {
        return false;
      }
    }
    return false;
  }
  expireSession() {
    const url = new URL(window.location.href);
    window.location.href = `${origin}/login?returnUrl=${encodeURIComponent(url.href)}`;
  }
  discover(username: string) {
    return this._http
      .get(`${environment.apiHost.bifrost}auth/discover/${username}`)
      .pipe(
        tap((res: any) => {
          window.location.href = `${environment.apiHost.bifrost}${res.loginUrl}${window.location.search}`;
        })
      );
  }
  logout() {
    this._clearSession();
  }
  private _clearSession() {
    this._clearSessionPreferences();
    this._destroySession();
  }
  private _clearSessionPreferences() {
    this._store.dispatch(LocalAppStateActions.clearSessionPreferences());
  }
  private _destroySession() {
    localStorage.removeItem(TokenType.Access);
    localStorage.removeItem(TokenType.Refresh);
    localStorage.removeItem(TokenType.Original);
    document.cookie =
      'ba.token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
}
