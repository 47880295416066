import { of } from 'rxjs';
import { SubSink } from 'subsink';
import { catchError, map } from 'rxjs/operators';
import { Injectable, OnDestroy, signal } from '@angular/core';
import {
  ForgetUsernameStatus,
  ForgotUsernameGQL,
} from 'graphql/bifrost/generated';

@Injectable({
  providedIn: 'root',
})
export class ForgotUsernameService implements OnDestroy {
  private _subs = new SubSink();
  readonly resetUsernameStatus = signal<{
    type: 'error' | 'success' | 'info' | null;
    message: string;
  }>({
    type: null,
    message: '',
  });

  constructor(private readonly _forgetUsernameMutation: ForgotUsernameGQL) {}

  requestForgetUsername(email: string) {
    return this._forgetUsernameMutation.mutate({ email }).pipe(
      map((res) => {
        if (res.data?.forgotUsername) {
          const status = res.data.forgotUsername;
          switch (status) {
            case ForgetUsernameStatus.EmailSent:
              this.resetUsernameStatus.set({
                type: 'success',
                message:
                  'An email with username has been successfully sent to your provided email address. Please check your inbox shortly.',
              });
              break;
            case ForgetUsernameStatus.SsoEnabled:
              this.resetUsernameStatus.set({
                type: 'info',
                message: 'Please use your hospital credentials to login.',
              });
              break;
            case ForgetUsernameStatus.UserNotFound:
              this.resetUsernameStatus.set({
                type: 'error',
                message:
                  'It appears that we do not have a Biome account for the entered email address. Please verify that your email was entered correctly, or please reach out to <a href="mailto:support@biome.io">support&#64;biome.io</a> for further assistance.',
              });
              break;
            default:
              this.resetUsernameStatus.set({
                type: 'error',
                message:
                  'An error occurred while processing your request. Please try again.',
              });
          }
        }
      }),
      catchError(() => {
        this.resetUsernameStatus.set({
          type: 'error',
          message:
            'An error occurred while processing your request. Please try again.',
        });
        return of(null);
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
