import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BusyBarService } from './busy-bar.service';

@Component({
  selector: 'lib-busy-bar',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-progress-bar
      mode="indeterminate"
      class="busy-bar"
      *ngIf="busy$ | async"
    ></mat-progress-bar>
  `,
  styles: [
    `
      .busy-bar {
        position: fixed;
        top: 0;
        z-index: 9999;
      }
    `,
  ],
  imports: [MatProgressBarModule, CommonModule],
})
export class BusyBarComponent {
  private readonly _busyBarService = inject(BusyBarService);
  readonly busy$ = this._busyBarService.busy$;
}
