import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { environment } from 'config/environments/environment';
import { SupportButtonService } from '@shared/lib/services/support-button.service';

@Component({
  selector: 'ba-support-button',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="support-button"
      *ngIf="(supportButtonHidden$ | async) === false"
    >
      <span class="hidden-label">
        <mat-icon
          *ngIf="enableHiding"
          class="material-symbols-outlined close-icon"
          (click)="hideButton()"
          >close</mat-icon
        >Biome Support</span
      >
      <a
        mat-mini-fab
        color="primary"
        [href]="baSupportUrl"
        target="_blank"
        class="help-icon-container"
      >
        <mat-icon class="material-symbols-outlined help-icon">help</mat-icon>
      </a>
    </div>
  `,
  styles: [
    `
      @use 'styles/variables';
      .support-button {
        right: 25px;
        bottom: 15px;
        position: absolute;
        z-index: 1;
        display: inline-flex;
        border-radius: 20px;
        overflow: hidden;
        transition: 0.2s;
        .help-icon-container {
          transition: 0.2s;
          .help-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            font-size: 39px;
            width: 34px;
            height: 35px;
            top: 3px;
            left: 3px;
            color: variables.$lightest;
          }
        }
        .hidden-label {
          opacity: 0;
          max-width: 0;
          white-space: nowrap;
          transition: 0.2s;
          font-size: large;
          color: variables.$lightest;
          display: flex;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;
        }
        &:hover {
          background-color: variables.$color-primary;
          .hidden-label {
            max-width: 200px;
            margin-left: 8px;
            opacity: 1;
          }
        }
        .close-icon {
          cursor: pointer;
          margin-right: 10px;
        }
      }
    `,
  ],
  imports: [NgIf, AsyncPipe, MatIconModule, MatButtonModule],
})
export class SupportButtonComponent {
  @Input() enableHiding = false;
  readonly baSupportUrl = environment.supportDeskUrl;
  readonly supportButtonHidden$ =
    this._supportButtonService.supportButtonHidden$;

  constructor(private readonly _supportButtonService: SupportButtonService) {}

  hideButton() {
    this._supportButtonService.toggle();
  }
}
