import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SupportButtonComponent } from '@shared/lib/components/support-button/support-button.component';
import { ResetPasswordService } from '@frontdoor/app/services/reset-password.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'ba-forgot-password',
  standalone: true,
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SupportButtonComponent,
  ],
})
export class ForgotPasswordComponent implements OnDestroy {
  private _subs = new SubSink();
  readonly username = this._fb.control<string>('', [
    Validators.required,
    Validators.email,
  ]);
  constructor(
    private readonly _fb: NonNullableFormBuilder,
    protected readonly _resetPasswordService: ResetPasswordService
  ) {}

  async onSubmit() {
    if (this.username.valid) {
      this._subs.sink = this._resetPasswordService
        .requestForgetPassword(this.username.value)
        .subscribe();
    }
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
