import { createAction, props } from '@ngrx/store';
import { PolicyResponse } from '@chiron/app/shared/graphql';

export const clearPermissions = createAction(
  '[User Service] Clear Permissions'
);
export const loadPermissions = createAction('[User Service] Load Permissions');
export const loadPermissionsSuccess = createAction(
  '[Permission Effect] Load Permissions Success',
  props<{ permissions: PolicyResponse[] }>()
);
export const loadPermissionsFailure = createAction(
  '[Permission Effect] Load Permissions Failure',
  props<{ error: any }>()
);
export const checkPermissions = createAction(
  '[Permission Effect] Check Permissions',
  props<{ permissions: PolicyResponse[] }>()
);
