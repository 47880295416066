export const environment = {
  production: false,
  apiHost: {
    caerus: `/api/caerus/`,
    athena: `/api/athena/`,
    odin: `/api/odin/`,
    coordinator: `/api/coordinator/`,
    bifrost: `/api/bifrost/`,
  },
  idleTimeout: 4 * 60 * 60,
  idleWarnTime: 1 * 60 * 60,
  autocompleteDebounceTime: 250,
  chironUrlsPostfix: 'dev.biomedata.io',
  tableauUrl: 'https://tableau.biomedata.io',
  auditorUrl: 'https://auditor.stag.biome.ws',
  supportDeskUrl:
    'https://biome-analytics.atlassian.net/servicedesk/customer/portals',
  gatewayUrl: 'https://bifrost.dev.biomedata.io',
  isDev: true,
  universalAnalyticsId: 'UA-193637549-1',
  enableAppCache: true,
  enableAnalytics: false,
  showDisclaimer: false,
  release: 'krumkake',
  agGridLicenseKey:
    'Using_this_{AG_Grid}_Enterprise_key_{AG-068851}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Biome}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Biome}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Biome}_need_to_be_licensed___{Biome}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_December_2025}____[v3]_[01]_MTc2NTc1NjgwMDAwMA==ec4fba02e4213c87139d8daebed27a16',
  appInsightsConnectionString:
    'InstrumentationKey=18d9bfb6-6dc6-4aea-ab7f-3ecf9f25da45;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/',
  posthogKey: 'phc_4O87YTguoxeEJ3Sc3nrOVSLqs4DUPeSjPOu0AkETNGp',
  myDocumentsFeatureKey: 'showMyDocumentsOnHomepageDev',
  submissionStatusFeatureKey: 'showSubmissionStatusDev',
  riskAuditImprovementChartFeatureKey:
    'disableAuditImprovementOverviewChartDev',
  useEntraIdFeatureKey: 'useEntraIdDev',
  riskAdjustedViewFeatureKey: 'enableRiskAdjustedForBiome',
  hideSimilaritySearchScores: false,
};
