import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SubSink } from 'subsink';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SupportButtonComponent } from '@shared/lib/components/support-button/support-button.component';
import { RouterModule } from '@angular/router';
import { ResetPasswordService } from '@frontdoor/app/services/reset-password.service';
import { AuthenticationService } from '@frontdoor/app/services/authentication.service';

class PasswordValidator {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return error;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
  static matchValues(matchTo: FormControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlValue = control.value;
      const matchToValue = matchTo.value;
      if (controlValue === matchToValue) {
        return null;
      } else {
        return { mismatch: true };
      }
    };
  }
}

@Component({
  selector: 'ba-change-password',
  standalone: true,
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SupportButtonComponent,
    RouterModule,
  ],
})
export class ChangePasswordComponent implements OnDestroy {
  private _subs = new SubSink();
  username = this._authenticator.username;
  readonly newPassword = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    PasswordValidator.patternValidator(new RegExp('(?=.*[0-9])'), {
      requiresDigit: true,
    }),
    PasswordValidator.patternValidator(new RegExp('(?=.*[A-Z])'), {
      requiresUppercase: true,
    }),
    PasswordValidator.patternValidator(new RegExp('(?=.*[a-z])'), {
      requiresLowercase: true,
    }),
    PasswordValidator.patternValidator(new RegExp('(?=.*[$@^!%*?&])'), {
      requiresSpecialChars: true,
    }),
  ]);
  readonly confirmPassword = new FormControl('', [
    Validators.required,
    PasswordValidator.matchValues(this.newPassword),
  ]);
  changePasswordForm: FormGroup = new FormGroup({
    newPassword: this.newPassword,
    confirmPassword: this.confirmPassword,
  });
  constructor(
    protected readonly _resetPasswordService: ResetPasswordService,
    private readonly _authenticator: AuthenticationService
  ) {
    this._subs.sink = this.newPassword?.valueChanges.subscribe(() => {
      this.confirmPassword?.updateValueAndValidity();
    });
  }
  get passwordValid() {
    return this.newPassword?.errors === null;
  }
  get requiredValid() {
    return !this.newPassword?.hasError('required');
  }
  get minLengthValid() {
    return !this.newPassword?.hasError('minlength') && this.requiredValid;
  }
  get requiresDigitValid() {
    return !this.newPassword?.hasError('requiresDigit');
  }
  get requiresUppercaseValid() {
    return !this.newPassword?.hasError('requiresUppercase');
  }
  get requiresLowercaseValid() {
    return !this.newPassword?.hasError('requiresLowercase');
  }
  get requiresSpecialCharsValid() {
    return !this.newPassword?.hasError('requiresSpecialChars');
  }

  async onSubmit() {
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.valid) {
      this._subs.sink = this._resetPasswordService
        .changePassword(this.changePasswordForm.value.confirmPassword)
        .subscribe();
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
