import { ColumnState, FilterState } from '@ag-grid-community/core';
import { createReducer, on } from '@ngrx/store';
import { LocalAppStateActions as LocalAppStateActions } from '../store-types';
import { environment } from 'config/environments/environment';
import { RowSpacingType } from '@shared/lib/components/toolbar-component/row-settings-toolbar.component';

export enum SessionPreference {
  HideWelcomeBanner = 'hideWelcomeBanner',
  HideHelpTip = 'hideHelpTip',
  HideDisclaimer = 'hideDisclaimer',
  HideMaintenanceNotice = 'hideMaintenanceNotice',
  HideTableauLicenseInfoPanel = 'hideTableauLicenseInfoPanel',
}
export enum UserPreference {
  HidePatientListDisclaimer = 'hidePatientListDisclaimer',
  HideEchoCasesDisclaimer = 'hideEchoCasesDisclaimer',
  HideRiskAuditsDisclaimer = 'hideRiskAuditsDisclaimer',
  HideDashboardUndoAlert = 'hideDashboardUndoAlert',
}

export interface LocalAppState {
  viewType: {
    [id: string]: string;
  };
  gridColumnState: {
    [gridId: string]: { state: ColumnState[]; timestamp: Date };
  };
  gridFilterState: {
    [gridId: string]: { state: FilterState; timestamp: Date };
  };
  sessionPreferences: {
    [key in SessionPreference]: boolean;
  };
  userPreferences: {
    [key in UserPreference]: boolean;
  };
  accessChangeComment: string;
  release: string;
  gridRowSpacing: {
    [gridId: string]: RowSpacingType;
  };
  gridRowStriping: {
    [gridId: string]: boolean;
  };
}
export const localAppStateFeatureKey = 'localAppState';
export const initialState: LocalAppState = {
  viewType: {},
  gridColumnState: {},
  gridFilterState: {},
  sessionPreferences: {
    [SessionPreference.HideWelcomeBanner]: false,
    [SessionPreference.HideHelpTip]: false,
    [SessionPreference.HideDisclaimer]: false,
    [SessionPreference.HideMaintenanceNotice]: false,
    [SessionPreference.HideTableauLicenseInfoPanel]: false,
  },
  userPreferences: {
    [UserPreference.HidePatientListDisclaimer]: false,
    [UserPreference.HideEchoCasesDisclaimer]: false,
    [UserPreference.HideRiskAuditsDisclaimer]: false,
    [UserPreference.HideDashboardUndoAlert]: false,
  },
  accessChangeComment: '',
  release: environment.release,
  gridRowSpacing: {},
  gridRowStriping: {},
};
export const reducer = createReducer(
  initialState,
  on(LocalAppStateActions.saveViewTypeSuccess, (state, action) => ({
    ...state,
    viewType: {
      ...state.viewType,
      [action.id]: action.viewType,
    },
  })),
  on(LocalAppStateActions.saveGridFilterStateSuccess, (state, action) => ({
    ...state,
    gridFilterState: {
      ...state.gridFilterState,
      [action.gridId]: { state: action.state, timestamp: action.timestamp },
    },
  })),
  on(LocalAppStateActions.clearGridFilterStateSuccess, (state, action) => {
    const updatedGridFilterState = { ...state.gridFilterState };
    delete updatedGridFilterState[action.gridId];
    return {
      ...state,
      gridFilterState: updatedGridFilterState,
    };
  }),
  on(LocalAppStateActions.clearGridColumnStateSuccess, (state, action) => {
    const updatedGridColumnState = { ...state.gridColumnState };
    delete updatedGridColumnState[action.gridId];
    return {
      ...state,
      gridColumnState: updatedGridColumnState,
    };
  }),
  on(LocalAppStateActions.saveGridColumnStateSuccess, (state, action) => ({
    ...state,
    gridColumnState: {
      ...state.gridColumnState,
      [action.gridId]: {
        state: action.gridColumnState,
        timestamp: action.timestamp,
      },
    },
  })),
  on(LocalAppStateActions.hideWelcomeBannerSuccess, (state) => ({
    ...state,
    sessionPreferences: {
      ...state.sessionPreferences,
      [SessionPreference.HideWelcomeBanner]: true,
    },
  })),
  on(LocalAppStateActions.hideMaintenanceNoticeSuccess, (state) => ({
    ...state,
    sessionPreferences: {
      ...state.sessionPreferences,
      [SessionPreference.HideMaintenanceNotice]: true,
    },
  })),
  on(LocalAppStateActions.hideHelpTipSuccess, (state) => ({
    ...state,
    sessionPreferences: {
      ...state.sessionPreferences,
      [SessionPreference.HideHelpTip]: true,
    },
  })),
  on(LocalAppStateActions.hideDisclaimerSuccess, (state) => ({
    ...state,
    sessionPreferences: {
      ...state.sessionPreferences,
      [SessionPreference.HideDisclaimer]: true,
    },
  })),
  on(LocalAppStateActions.hidePatientListDisclaimerSuccess, (state) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      [UserPreference.HidePatientListDisclaimer]: true,
    },
  })),
  on(LocalAppStateActions.hideDashboardUndoAlertSuccess, (state) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      [UserPreference.HideDashboardUndoAlert]: true,
    },
  })),
  on(LocalAppStateActions.clearSessionPreferencesSuccess, (state) => ({
    ...state,
    sessionPreferences: initialState.sessionPreferences,
  })),
  on(LocalAppStateActions.loadLocalAppStateSuccess, (state, action) => ({
    ...state,
    ...action.localAppState,
  })),
  on(LocalAppStateActions.saveAccessChangeCommentSuccess, (state, action) => ({
    ...state,
    accessChangeComment: action.comment,
  })),
  on(LocalAppStateActions.saveGridRowSpacingSuccess, (state, action) => ({
    ...state,
    gridRowSpacing: {
      ...state.gridRowSpacing,
      [action.gridId]: action.size,
    },
  })),
  on(LocalAppStateActions.saveGridRowStripingSuccess, (state, action) => ({
    ...state,
    gridRowStriping: {
      ...state.gridRowStriping,
      [action.gridId]: action.striping,
    },
  })),
  on(LocalAppStateActions.hideTableauLicensePanelInfoSuccess, (state) => ({
    ...state,
    sessionPreferences: {
      ...state.sessionPreferences,
      [SessionPreference.HideTableauLicenseInfoPanel]: true,
    },
  }))
);
