import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from 'config/environments/environment';
import { LicenseManager } from '@ag-grid-enterprise/core';

LicenseManager.setLicenseKey(environment.agGridLicenseKey);
bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
