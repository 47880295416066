import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values. */
  JSON: { input: any; output: any };
};

export type AccessSnapshot = {
  user: UserAccessSnapshot;
};

export type AdGroup = {
  __typename?: 'AdGroup';
  /** Every entry in the directory has a distinguished name (DN). The DN is the name that uniquely identifies an entry in the directory. It is a sequence of relative distinguished names (RDN) connected by commas. An RDN is an attribute with an associated value in the form attribute=value; normally expressed in a UTF-8 string format. RDN attributes consist of commonName (CN), organizationalUnitName (OU) and so on. */
  distinguishedName: Scalars['String']['output'];
  /** Name of the group */
  name?: Maybe<Scalars['String']['output']>;
};

export type AggregateBeat = {
  __typename?: 'AggregateBeat';
  actionTaken?: Maybe<Scalars['Boolean']['output']>;
  actionable?: Maybe<Scalars['Boolean']['output']>;
  beats: Array<Beat>;
  careEntity: CareEntity;
  careEntityId: Scalars['String']['output'];
  children?: Maybe<Array<AggregateBeat>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  diseaseStateModule: DiseaseStateModule;
  diseaseStateModuleId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  matchRate?: Maybe<Scalars['Float']['output']>;
  month: Month;
  parent?: Maybe<AggregateBeat>;
  state: BeatState;
  triggerJiraReleaseTicket?: Maybe<Scalars['Boolean']['output']>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export type AscendOutput = {
  __typename?: 'AscendOutput';
  componentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataflowRun: DataflowRun;
  id: Scalars['String']['output'];
  originalLocation: AscendOutputLocation;
  state: AscendOutputState;
  storedLocation: AscendOutputLocation;
  type: AscendOutputType;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
};

export type AscendOutputLocation = {
  __typename?: 'AscendOutputLocation';
  location?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum AscendOutputState {
  Done = 'Done',
  Error = 'Error',
  Pending = 'Pending',
}

export enum AscendOutputType {
  DataFeed = 'DataFeed',
  WriteConnector = 'WriteConnector',
}

export type Beat = {
  __typename?: 'Beat';
  actionTaken?: Maybe<Scalars['Boolean']['output']>;
  actionable?: Maybe<Scalars['Boolean']['output']>;
  aggregateBeats?: Maybe<Array<AggregateBeat>>;
  careEntity: CareEntity;
  careEntityId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  datasetInstances: Array<DatasetInstance>;
  diseaseStateModule: DiseaseStateModule;
  diseaseStateModuleId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lastEventId?: Maybe<Scalars['String']['output']>;
  lifelineEvents?: Maybe<Array<LifelineEvent>>;
  month: Month;
  requestedDate?: Maybe<Scalars['DateTime']['output']>;
  requiresCrosswalk?: Maybe<Scalars['Boolean']['output']>;
  state: BeatState;
  submissions?: Maybe<Array<Submission>>;
  triggerJiraReleaseTicket?: Maybe<Scalars['Boolean']['output']>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export enum BeatState {
  Complete = 'COMPLETE',
  Inprogress = 'INPROGRESS',
  Unfilled = 'UNFILLED',
}

export enum BenchmarkType {
  LikeGroup = 'LikeGroup',
  National = 'National',
}

export type Bookmark = {
  __typename?: 'Bookmark';
  category: BookmarkCategory;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  /** Description of the bookmark */
  description?: Maybe<Scalars['String']['output']>;
  /** uuid as primary key */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  url: Scalars['String']['output'];
  user?: Maybe<User>;
  users?: Maybe<Array<User>>;
};

export enum BookmarkCategory {
  Dashboard = 'DASHBOARD',
  Homepage = 'HOMEPAGE',
}

export type CareEntity = {
  __typename?: 'CareEntity';
  active: Scalars['Boolean']['output'];
  aggregateBeats: Array<AggregateBeat>;
  aggregated: Scalars['Boolean']['output'];
  alternateNames?: Maybe<Array<Scalars['String']['output']>>;
  anonymousCode?: Maybe<Scalars['String']['output']>;
  beats: Array<Beat>;
  business?: Maybe<Scalars['String']['output']>;
  children?: Maybe<Array<CareEntity>>;
  city?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  dataframes: Array<DataFrame>;
  description: Scalars['String']['output'];
  diseaseStateModules: Array<DiseaseStateModule>;
  id: Scalars['ID']['output'];
  ids?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  parents?: Maybe<Array<CareEntity>>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  submissions: Array<Submission>;
  supportedApps?: Maybe<Array<SupportedApps>>;
  teaching: Scalars['Boolean']['output'];
  tenants: Array<Tenant>;
  type: CareEntityType;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  usGeoRegion?: Maybe<UsGeoRegion>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum CareEntityType {
  Campus = 'Campus',
  Clinic = 'Clinic',
  Hospital = 'Hospital',
  Region = 'Region',
  System = 'System',
}

export type CathAudit = {
  __typename?: 'CathAudit';
  arrivalDate?: Maybe<Scalars['DateTime']['output']>;
  auditId: Scalars['Float']['output'];
  biomeETLVersion?: Maybe<Scalars['String']['output']>;
  biomeEncounterId: Scalars['Float']['output'];
  biomeUpdateDt: Scalars['DateTime']['output'];
  careEntity: CareEntity;
  careEntityId: Scalars['String']['output'];
  careEntityName: Scalars['String']['output'];
  diagnosisPOACodeList?: Maybe<Scalars['String']['output']>;
  dischargeDate?: Maybe<Scalars['DateTime']['output']>;
  dischargeQuarter?: Maybe<Scalars['String']['output']>;
  dischargeYear?: Maybe<Scalars['String']['output']>;
  diseaseStateModule: DiseaseStateModule;
  diseaseStateModuleId: Scalars['String']['output'];
  encounterId?: Maybe<Scalars['String']['output']>;
  hospital?: Maybe<Scalars['String']['output']>;
  keyField?: Maybe<Scalars['String']['output']>;
  keyFieldValue?: Maybe<Scalars['String']['output']>;
  mrn?: Maybe<Scalars['String']['output']>;
  procedure?: Maybe<Scalars['String']['output']>;
  procedureDate?: Maybe<Scalars['DateTime']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  registryId?: Maybe<Scalars['Float']['output']>;
  reviewTriggerDescription?: Maybe<Scalars['String']['output']>;
  riskReview?: Maybe<Scalars['String']['output']>;
  riskValue?: Maybe<Scalars['String']['output']>;
  surgicalQuarter?: Maybe<Scalars['String']['output']>;
  surgicalYear?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
  trails: Array<CathAuditTrail>;
  visitId?: Maybe<Scalars['Float']['output']>;
  visitTiming?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type CathAuditTrail = {
  __typename?: 'CathAuditTrail';
  auditTask?: Maybe<CathAudit>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  diseaseStateModuleId: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  latestStudyOnRecord: Scalars['Boolean']['output'];
  notes: Scalars['String']['output'];
  /** Tracks the status of audit trail */
  status: RiskAuditTrailStatus;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type ClientDataFile = {
  __typename?: 'ClientDataFile';
  blobUrl?: Maybe<Scalars['String']['output']>;
  children?: Maybe<Array<ClientDataFile>>;
  clientDataFileFamily?: Maybe<ClientDataFileFamily>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  dataframes?: Maybe<Array<DataFrame>>;
  deletedAt: Scalars['DateTime']['output'];
  extensionType: ExtensionType;
  id: Scalars['ID']['output'];
  ingestedAt?: Maybe<Scalars['DateTime']['output']>;
  ingestionFileId?: Maybe<Scalars['Float']['output']>;
  ingestionState: IngestionState;
  isProcessed: Scalars['Boolean']['output'];
  jiraIssueId?: Maybe<Scalars['String']['output']>;
  jiraIssues?: Maybe<Array<JiraIssue>>;
  lastEventId?: Maybe<Scalars['String']['output']>;
  lifelineEvents: Array<LifelineEvent>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<ClientDataFile>;
  parentId?: Maybe<Scalars['String']['output']>;
  rootTriggerId?: Maybe<Scalars['String']['output']>;
  size: Scalars['Int']['output'];
  submissions: Submission;
  submittedAt: Scalars['DateTime']['output'];
  submittedBy?: Maybe<User>;
  submittedById?: Maybe<Scalars['String']['output']>;
  supersedesId?: Maybe<Scalars['String']['output']>;
  trails?: Maybe<Array<ClientDataFileTrail>>;
  triggerId?: Maybe<Scalars['String']['output']>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
};

export type ClientDataFileFamily = {
  __typename?: 'ClientDataFileFamily';
  clientDataFiles: Array<ClientDataFile>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  expectedCount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  lastEventId?: Maybe<Scalars['String']['output']>;
  lifelineEvents?: Maybe<Array<LifelineEvent>>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
};

export enum ClientDataFileState {
  Ignore = 'Ignore',
  New = 'New',
  OnHold = 'OnHold',
  Planned = 'Planned',
}

export type ClientDataFileTrail = {
  __typename?: 'ClientDataFileTrail';
  clientDataFile: ClientDataFile;
  clientDataFileId: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isLatest: Scalars['Boolean']['output'];
  state?: Maybe<ClientDataFileState>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type ClinicalProgram = {
  __typename?: 'ClinicalProgram';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  diseaseStateModules?: Maybe<Array<DiseaseStateModule>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
};

export type CreateBookmarkInput = {
  category: BookmarkCategory;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  url: Scalars['String']['input'];
  users?: InputMaybe<Array<UpdateUserInput>>;
};

export type CreateMaintenanceSlotInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  endTime: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['DateTime']['input'];
  tenants?: InputMaybe<Array<UpdateTenantInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type CreateUrlMapInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  destination: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  method: RequestMethod;
  priority: Scalars['Int']['input'];
  responseType: ResponseType;
  source: Scalars['String']['input'];
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type CreateUserInput = {
  adGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<CreateUserInput>;
  distributionLists?: InputMaybe<Array<UpdateDistributionListInput>>;
  email: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Time when the user was enabled. This is used to keep certain items on the UI disabled until this change is synced to Azure AD. */
  enabledTime?: InputMaybe<Scalars['DateTime']['input']>;
  firstName: Scalars['String']['input'];
  homepage?: InputMaybe<CreateBookmarkInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastLogin?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  notifications?: InputMaybe<Array<UpdateNotificationInput>>;
  organizationalUnit?: InputMaybe<Scalars['String']['input']>;
  otherEmails?: InputMaybe<Array<OtherEmailInput>>;
  passwordNeverExpires?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UpdateRoleInput>>;
  root?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<UserStatus>;
  submitsData?: InputMaybe<Scalars['Boolean']['input']>;
  tenant?: InputMaybe<UpdateTenantInput>;
  tenantPolicies?: InputMaybe<Array<TenantPolicyInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  twoFactor?: InputMaybe<Scalars['Boolean']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<CreateUserInput>;
  /** Unique username */
  username: Scalars['String']['input'];
};

export type DataFrame = {
  __typename?: 'DataFrame';
  auditorId?: Maybe<Scalars['String']['output']>;
  availableToETL: EtlAvailability;
  careEntities?: Maybe<Array<CareEntity>>;
  clientDataFile: ClientDataFile;
  clientDataFileId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  dataframeMclChunks?: Maybe<Array<DataframeMclChunk>>;
  datasetInstances: Array<DatasetInstance>;
  deletedAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastEventId?: Maybe<Scalars['String']['output']>;
  lifelineEvents: Array<LifelineEvent>;
  location: Scalars['String']['output'];
  maskedAt?: Maybe<Scalars['DateTime']['output']>;
  maskedDataFrameLocation?: Maybe<Scalars['String']['output']>;
  maskedDataFrameName?: Maybe<Scalars['String']['output']>;
  maskedLocations?: Maybe<Array<MaskedDataframeLocations>>;
  maskerState: MaskerState;
  mclStatus: DataframeMclStatus;
  name: Scalars['String']['output'];
  rowCount: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  subsetId: Scalars['Float']['output'];
  tenant?: Maybe<Tenant>;
  tenantId?: Maybe<Scalars['String']['output']>;
  unknownCareEntities?: Maybe<Array<Scalars['String']['output']>>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  validation: DataframeValidationStatus;
};

export type Dataflow = {
  __typename?: 'Dataflow';
  components?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataFeedInputs: Array<Scalars['String']['output']>;
  dataFeedOutputs: Array<Scalars['String']['output']>;
  dataServiceName: Scalars['String']['output'];
  datafeedTransforms: Array<Scalars['String']['output']>;
  dataflowRuns: Array<DataflowRun>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  readConnectors: Array<Scalars['String']['output']>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  version: Scalars['Float']['output'];
  writeConnectors: Array<Scalars['String']['output']>;
};

export type DataflowRun = {
  __typename?: 'DataflowRun';
  allowExpiration: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataflow: Dataflow;
  dataflowId: Scalars['String']['output'];
  dataflowRunUUID?: Maybe<Scalars['String']['output']>;
  datasetInstances?: Maybe<Array<DatasetInstance>>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  outputs: Array<AscendOutput>;
  startTime: Scalars['DateTime']['output'];
  status: DataflowRunStatus;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
};

export enum DataflowRunStatus {
  Complete = 'Complete',
  InProgress = 'InProgress',
  Incomplete = 'Incomplete',
}

export enum DataframeLocationType {
  MySql = 'MySQL',
  Parquet = 'Parquet',
  ParquetLocation = 'ParquetLocation',
}

/** Dataframe Chunks that have been loaded in mcl */
export type DataframeMclChunk = {
  __typename?: 'DataframeMclChunk';
  casesAdded: Scalars['Float']['output'];
  chunkNumber: Scalars['Float']['output'];
  dataframe: DataFrame;
  dataframeId: Scalars['String']['output'];
  elapsedTime: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  rootTriggerId: Scalars['String']['output'];
};

export enum DataframeMclStatus {
  DataLoadedInMcl = 'DataLoadedInMcl',
  DataSubmittedToMcl = 'DataSubmittedToMcl',
  Failed = 'Failed',
  Inprogress = 'Inprogress',
  Pending = 'Pending',
}

export enum DataframeValidationStatus {
  Failed = 'Failed',
  ManualOverride = 'ManualOverride',
  Pending = 'Pending',
  Success = 'Success',
}

export type Dataset = {
  __typename?: 'Dataset';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  criticalDiseaseStateModules?: Maybe<Array<DiseaseStateModule>>;
  datasetVersions?: Maybe<Array<DatasetVersion>>;
  deadlines?: Maybe<Array<Deadline>>;
  description: Scalars['String']['output'];
  diseaseStateModules?: Maybe<Array<DiseaseStateModule>>;
  id: Scalars['Int']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if past submissions should be dropped from the data pipeline when new data for an overlapping time period is provided. If this is marked "yes" old data is removed and replaced entirely by the new submission */
  isDropAndLoad?: Maybe<Scalars['Boolean']['output']>;
  isRegistry: Scalars['Boolean']['output'];
  managingSociety: Scalars['String']['output'];
  name: Scalars['String']['output'];
  primaryDiseaseStateModules?: Maybe<Array<DiseaseStateModule>>;
  submissions?: Maybe<Array<Submission>>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
};

export type DatasetInstance = {
  __typename?: 'DatasetInstance';
  beats?: Maybe<Array<Beat>>;
  careEntity?: Maybe<CareEntity>;
  careEntityId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  dataflowRuns?: Maybe<Array<DataflowRun>>;
  dataframes?: Maybe<Array<DataFrame>>;
  dataset?: Maybe<Dataset>;
  datasetId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isLatest: Scalars['Boolean']['output'];
  isReady: Scalars['Boolean']['output'];
  lastEventId?: Maybe<Scalars['String']['output']>;
  lifelineEvents?: Maybe<Array<LifelineEvent>>;
  matches?: Maybe<Scalars['Float']['output']>;
  month: Month;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  version: Scalars['String']['output'];
  volume?: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type DatasetVersion = {
  __typename?: 'DatasetVersion';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  dataset: Dataset;
  datasetId: Scalars['Int']['output'];
  datasetVersionFields?: Maybe<Array<DatasetVersionField>>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  versionNumber: Scalars['Float']['output'];
};

export type DatasetVersionField = {
  __typename?: 'DatasetVersionField';
  datasetVersion: DatasetVersion;
  datasetVersionId: Scalars['ID']['output'];
  fieldId: Scalars['Int']['output'];
  sequenceNumber?: Maybe<Scalars['Int']['output']>;
};

export type Deadline = {
  __typename?: 'Deadline';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  dataset: Dataset;
  datasetId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  monthIncluded?: Maybe<Scalars['String']['output']>;
  monthSubmissionDeadline?: Maybe<Scalars['DateTime']['output']>;
  quarterIncluded?: Maybe<Scalars['String']['output']>;
  quarterSubmissionDeadline?: Maybe<Scalars['DateTime']['output']>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
};

export type DiseaseStateModule = {
  __typename?: 'DiseaseStateModule';
  aggregateBeats: Array<AggregateBeat>;
  beats: Array<Beat>;
  careEntities?: Maybe<Array<CareEntity>>;
  clinicalProgram: ClinicalProgram;
  clinicalProgramId: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  criticalDatasets?: Maybe<Array<Dataset>>;
  datasets?: Maybe<Array<Dataset>>;
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  primaryDataset?: Maybe<Dataset>;
  primaryDatasetId?: Maybe<Scalars['Int']['output']>;
  sortOrder: Scalars['Int']['output'];
  supportedApps?: Maybe<Array<SupportedApps>>;
  tableauExtracts?: Maybe<Array<TableauExtract>>;
  tableauReleaseParameters?: Maybe<Array<TableauReleaseParameter>>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  workbookGroups?: Maybe<Array<WorkbookGroup>>;
  workbooks?: Maybe<Array<Workbook>>;
};

/** Distribution List */
export type DistributionList = {
  __typename?: 'DistributionList';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  files?: Maybe<Array<File>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tenant: Tenant;
  tenantId: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  users?: Maybe<Array<User>>;
};

export type EchoFinding = {
  __typename?: 'EchoFinding';
  ageGroup?: Maybe<Scalars['String']['output']>;
  aiDecel?: Maybe<Scalars['Float']['output']>;
  aiDecelText?: Maybe<Scalars['String']['output']>;
  aipht?: Maybe<Scalars['Float']['output']>;
  aiphtText?: Maybe<Scalars['String']['output']>;
  aorticRoot?: Maybe<Scalars['String']['output']>;
  aorticRootText?: Maybe<Scalars['String']['output']>;
  asd?: Maybe<Scalars['String']['output']>;
  asdText?: Maybe<Scalars['String']['output']>;
  avArea?: Maybe<Scalars['Float']['output']>;
  avAreaText?: Maybe<Scalars['String']['output']>;
  avInsufficiency?: Maybe<Scalars['String']['output']>;
  avInsufficiencyText?: Maybe<Scalars['String']['output']>;
  avMeanGradient?: Maybe<Scalars['Float']['output']>;
  avMeanGradientText?: Maybe<Scalars['String']['output']>;
  avPeakGradient?: Maybe<Scalars['Float']['output']>;
  avPeakGradientText?: Maybe<Scalars['String']['output']>;
  avPeakVelocity?: Maybe<Scalars['Float']['output']>;
  avPeakVelocityText?: Maybe<Scalars['String']['output']>;
  avStenosis?: Maybe<Scalars['String']['output']>;
  avStenosisText?: Maybe<Scalars['String']['output']>;
  avStructure?: Maybe<Scalars['String']['output']>;
  avStructureText?: Maybe<Scalars['String']['output']>;
  avVegetation?: Maybe<Scalars['String']['output']>;
  avVegetationText?: Maybe<Scalars['String']['output']>;
  biomeCaseUid?: Maybe<Scalars['String']['output']>;
  biomeEncounterId: Scalars['Float']['output'];
  biomePatientUid?: Maybe<Scalars['String']['output']>;
  biomeUpdateDt: Scalars['DateTime']['output'];
  cardiomyopathy?: Maybe<Scalars['String']['output']>;
  cardiomyopathyText?: Maybe<Scalars['String']['output']>;
  careEntity: CareEntity;
  careEntityId: Scalars['String']['output'];
  careEntityName: Scalars['String']['output'];
  caseId?: Maybe<Scalars['String']['output']>;
  contrast?: Maybe<Scalars['String']['output']>;
  contrastText?: Maybe<Scalars['String']['output']>;
  dataset?: Maybe<Scalars['String']['output']>;
  empi?: Maybe<Scalars['String']['output']>;
  encounterId?: Maybe<Scalars['String']['output']>;
  estimatedPap?: Maybe<Scalars['String']['output']>;
  estimatedPapText?: Maybe<Scalars['String']['output']>;
  excludeFromAnalysis?: Maybe<Scalars['Float']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  followups: Array<EchoFindingFollowup>;
  gender?: Maybe<Scalars['String']['output']>;
  ivSdThickness?: Maybe<Scalars['Float']['output']>;
  ivSdThicknessText?: Maybe<Scalars['String']['output']>;
  lVdDiastole?: Maybe<Scalars['String']['output']>;
  lVdDiastoleText?: Maybe<Scalars['String']['output']>;
  lVdSystole?: Maybe<Scalars['String']['output']>;
  lVdSystoleText?: Maybe<Scalars['String']['output']>;
  laSize?: Maybe<Scalars['String']['output']>;
  laSizeText?: Maybe<Scalars['String']['output']>;
  latestStudyOnRecord: Scalars['Boolean']['output'];
  lvDiastolicFunction?: Maybe<Scalars['String']['output']>;
  lvDiastolicFunctionText?: Maybe<Scalars['String']['output']>;
  lvFunction?: Maybe<Scalars['String']['output']>;
  lvFunctionText?: Maybe<Scalars['String']['output']>;
  lvSize?: Maybe<Scalars['String']['output']>;
  lvSizeText?: Maybe<Scalars['String']['output']>;
  lvWallMotion?: Maybe<Scalars['String']['output']>;
  lvWallMotionText?: Maybe<Scalars['String']['output']>;
  lvWallThickness?: Maybe<Scalars['String']['output']>;
  lvWallThicknessText?: Maybe<Scalars['String']['output']>;
  lveFcubed?: Maybe<Scalars['String']['output']>;
  lveFcubedText?: Maybe<Scalars['String']['output']>;
  lvef?: Maybe<Scalars['Float']['output']>;
  lvefMod?: Maybe<Scalars['Float']['output']>;
  lvefModText?: Maybe<Scalars['String']['output']>;
  lvefRange?: Maybe<Scalars['String']['output']>;
  lvefRangeText?: Maybe<Scalars['String']['output']>;
  lvefText?: Maybe<Scalars['String']['output']>;
  lviDdMm?: Maybe<Scalars['String']['output']>;
  lviDdMmText?: Maybe<Scalars['String']['output']>;
  mrn?: Maybe<Scalars['String']['output']>;
  mvMeanPg?: Maybe<Scalars['String']['output']>;
  mvMeanPgText?: Maybe<Scalars['String']['output']>;
  mvRegurgVolume?: Maybe<Scalars['String']['output']>;
  mvRegurgVolumeText?: Maybe<Scalars['String']['output']>;
  mvRegurgitation?: Maybe<Scalars['String']['output']>;
  mvRegurgitationText?: Maybe<Scalars['String']['output']>;
  mvStenosis?: Maybe<Scalars['String']['output']>;
  mvStenosisText?: Maybe<Scalars['String']['output']>;
  mvStructure?: Maybe<Scalars['String']['output']>;
  mvStructureText?: Maybe<Scalars['String']['output']>;
  mvVegetation?: Maybe<Scalars['String']['output']>;
  mvVegetationText?: Maybe<Scalars['String']['output']>;
  mvapht?: Maybe<Scalars['String']['output']>;
  mvaphtText?: Maybe<Scalars['String']['output']>;
  orderDt?: Maybe<Scalars['DateTime']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  pWdThickness?: Maybe<Scalars['String']['output']>;
  pWdThicknessText?: Maybe<Scalars['String']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
  pericardialFluid?: Maybe<Scalars['String']['output']>;
  pericardialFluidText?: Maybe<Scalars['String']['output']>;
  previousSavr?: Maybe<Scalars['Float']['output']>;
  previousTavr?: Maybe<Scalars['Float']['output']>;
  procedureDt: Scalars['DateTime']['output'];
  procedureName?: Maybe<Scalars['String']['output']>;
  pvStenosis?: Maybe<Scalars['String']['output']>;
  pvStenosisText?: Maybe<Scalars['String']['output']>;
  pvStructure?: Maybe<Scalars['String']['output']>;
  pvStructureText?: Maybe<Scalars['String']['output']>;
  pvVegetation?: Maybe<Scalars['String']['output']>;
  pvVegetationText?: Maybe<Scalars['String']['output']>;
  raSize?: Maybe<Scalars['String']['output']>;
  raSizeText?: Maybe<Scalars['String']['output']>;
  readingProviderId?: Maybe<Scalars['String']['output']>;
  readingProviderName?: Maybe<Scalars['String']['output']>;
  referringProvider?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  resultSignedDt?: Maybe<Scalars['DateTime']['output']>;
  room?: Maybe<Scalars['String']['output']>;
  rvDiameter?: Maybe<Scalars['String']['output']>;
  rvDiameterText?: Maybe<Scalars['String']['output']>;
  rvFunction?: Maybe<Scalars['String']['output']>;
  rvFunctionText?: Maybe<Scalars['String']['output']>;
  rvSize?: Maybe<Scalars['String']['output']>;
  rvSizeText?: Maybe<Scalars['String']['output']>;
  rvSystolicPressure?: Maybe<Scalars['String']['output']>;
  rvSystolicPressureText?: Maybe<Scalars['String']['output']>;
  rvsptr?: Maybe<Scalars['String']['output']>;
  rvsptrText?: Maybe<Scalars['String']['output']>;
  sonographerId?: Maybe<Scalars['String']['output']>;
  sonographerName?: Maybe<Scalars['String']['output']>;
  strainImaging?: Maybe<Scalars['String']['output']>;
  strainImagingText?: Maybe<Scalars['String']['output']>;
  studyCountByMrn?: Maybe<Scalars['Float']['output']>;
  studyQuality?: Maybe<Scalars['String']['output']>;
  studyQualityText?: Maybe<Scalars['String']['output']>;
  symptoms?: Maybe<Scalars['String']['output']>;
  symptomsText?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
  tRmaxPg?: Maybe<Scalars['String']['output']>;
  tRmaxPgText?: Maybe<Scalars['String']['output']>;
  tRmeanPg?: Maybe<Scalars['String']['output']>;
  tRmeanPgText?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
  thrombiMasses?: Maybe<Scalars['String']['output']>;
  thrombiMassesText?: Maybe<Scalars['String']['output']>;
  tvMaxPg?: Maybe<Scalars['Float']['output']>;
  tvMaxPgText?: Maybe<Scalars['String']['output']>;
  tvMeanPg?: Maybe<Scalars['Float']['output']>;
  tvMeanPgText?: Maybe<Scalars['String']['output']>;
  tvRegurgitation?: Maybe<Scalars['String']['output']>;
  tvRegurgitationText?: Maybe<Scalars['String']['output']>;
  tvStenosis?: Maybe<Scalars['String']['output']>;
  tvStenosisText?: Maybe<Scalars['String']['output']>;
  tvStructure?: Maybe<Scalars['String']['output']>;
  tvStructureText?: Maybe<Scalars['String']['output']>;
  tvVegetation?: Maybe<Scalars['String']['output']>;
  tvVegetationText?: Maybe<Scalars['String']['output']>;
  tveaRatio?: Maybe<Scalars['Float']['output']>;
  tveaRatioText?: Maybe<Scalars['String']['output']>;
  visitType?: Maybe<Scalars['String']['output']>;
  vsd?: Maybe<Scalars['String']['output']>;
  vsdText?: Maybe<Scalars['String']['output']>;
};

export type EchoFindingField = {
  __typename?: 'EchoFindingField';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  displayRank: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  modules?: Maybe<Array<EchoFindingModule>>;
  name: Scalars['String']['output'];
  phi: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  visibility: VisibilityType;
};

export type EchoFindingFollowup = {
  __typename?: 'EchoFindingFollowup';
  biomeCaseUid: Scalars['String']['output'];
  biomePatientUid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  finding?: Maybe<EchoFinding>;
  id: Scalars['ID']['output'];
  latestStudyOnRecord: Scalars['Boolean']['output'];
  notes: Scalars['String']['output'];
  statusId: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type EchoFindingModule = {
  __typename?: 'EchoFindingModule';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  fields?: Maybe<Array<EchoFindingField>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
};

export enum EnforcerType {
  Admin = 'Admin',
  Case = 'Case',
  Clinician = 'Clinician',
  Dashboard = 'Dashboard',
  DiseaseStateModule = 'DiseaseStateModule',
  Entity = 'Entity',
  File = 'File',
  General = 'General',
  Initiative = 'Initiative',
  PatientList = 'PatientList',
  Tenant = 'Tenant',
  User = 'User',
}

export enum EtlAvailability {
  Available = 'AVAILABLE',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

export enum ExtensionType {
  Avi = 'AVI',
  Cs3916 = 'CS3916',
  Csv = 'CSV',
  Dat = 'DAT',
  Db = 'DB',
  Docx = 'DOCX',
  Em5144 = 'EM5144',
  Filepart = 'FILEPART',
  Fl5144 = 'FL5144',
  Fx5144 = 'FX5144',
  Gpg = 'GPG',
  Gq1808 = 'GQ1808',
  Gq2892 = 'GQ2892',
  Gq3448 = 'GQ3448',
  Gq3968 = 'GQ3968',
  Gq4304 = 'GQ4304',
  Gq4380 = 'GQ4380',
  Gq5144 = 'GQ5144',
  Html = 'HTML',
  Job = 'JOB',
  Ly5144 = 'LY5144',
  Mht = 'MHT',
  Mp4 = 'MP4',
  Msg = 'MSG',
  Ods = 'ODS',
  Other = 'Other',
  Pdf = 'PDF',
  Pgp = 'PGP',
  Pptx = 'PPTX',
  Prn = 'PRN',
  Rep = 'REP',
  Rpt = 'RPT',
  Rtf = 'RTF',
  Samplexlsx = 'SAMPLEXLSX',
  Sevenz = 'SEVENZ',
  Sh = 'SH',
  Sql = 'SQL',
  Tsv = 'TSV',
  Txt = 'TXT',
  Unsupported = 'UNSUPPORTED',
  Url = 'URL',
  Xls = 'XLS',
  Xlsm = 'XLSM',
  Xlsx = 'XLSX',
  Xml = 'XML',
  Zip = 'ZIP',
}

/** File */
export type File = {
  __typename?: 'File';
  category: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  distributionLists?: Maybe<Array<DistributionList>>;
  extension: Scalars['String']['output'];
  fileLabel?: Maybe<FileLabel>;
  /** uuid as primary key */
  id: Scalars['ID']['output'];
  isLatest?: Maybe<Scalars['Boolean']['output']>;
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phi?: Maybe<Scalars['Boolean']['output']>;
  tenant?: Maybe<Tenant>;
  tenantId?: Maybe<Scalars['String']['output']>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  url: Scalars['String']['output'];
  users: Array<FileUser>;
  version: Scalars['Int']['output'];
};

export type FileDistributionList = {
  __typename?: 'FileDistributionList';
  distributionLists?: Maybe<Array<DistributionList>>;
  file: File;
  isDirect: Scalars['Boolean']['output'];
};

export type FileInput = {
  category: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extension: Scalars['String']['input'];
  fileLabel?: InputMaybe<UpdateFileLabelInput>;
  id: Scalars['ID']['input'];
  isLatest?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phi?: InputMaybe<Scalars['Boolean']['input']>;
  tenant?: InputMaybe<UpdateTenantInput>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  updateById?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type FileLabel = {
  __typename?: 'FileLabel';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  files?: Maybe<Array<File>>;
  /** uuid as primary key */
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy: User;
};

export type FileUser = {
  __typename?: 'FileUser';
  distributionLists?: Maybe<Array<DistributionList>>;
  isDirect?: Maybe<Scalars['Boolean']['output']>;
  user: User;
};

export type IdentityProvider = {
  __typename?: 'IdentityProvider';
  certificate: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  defaultRoles?: Maybe<Array<Role>>;
  description: Scalars['String']['output'];
  emailAttribute: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  entryPoint: Scalars['String']['output'];
  firstNameAttribute: Scalars['String']['output'];
  forceAuthentication: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identifierFormat: Scalars['String']['output'];
  lastNameAttribute: Scalars['String']['output'];
  loginRoute: LoginRoute;
  logoutUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  provisionUsers: Scalars['Boolean']['output'];
  tenant?: Maybe<Tenant>;
  tenantId: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  updateUsers: Scalars['Boolean']['output'];
};

export enum IngestionState {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

export type JiraIssue = {
  __typename?: 'JiraIssue';
  clientDataFile?: Maybe<ClientDataFile>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Jira Issue Key */
  key: Scalars['String']['output'];
  lastEventId?: Maybe<Scalars['String']['output']>;
  month: Month;
  rootTriggerId?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  type: JiraIssueType;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export enum JiraIssueType {
  Bug = 'Bug',
  Epic = 'Epic',
  Story = 'Story',
}

export type Job = {
  __typename?: 'Job';
  additionalInfo: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  events?: Maybe<Array<JobEvent>>;
  id: Scalars['ID']['output'];
  /** The type of job to be created */
  jobType: JobType;
  parent?: Maybe<Job>;
  parentId?: Maybe<Scalars['String']['output']>;
  /** The roles for which the job was created */
  roles?: Maybe<Array<Role>>;
  /** The status of the job */
  status: JobEventType;
  statusTime: Scalars['DateTime']['output'];
  /** The tenants for which the job was created */
  tenants?: Maybe<Array<Tenant>>;
  /** The users for which the job was created */
  users?: Maybe<Array<User>>;
};

export type JobEvent = {
  __typename?: 'JobEvent';
  /** The type of event that occurred */
  eventType: JobEventType;
  id: Scalars['ID']['output'];
  job: Job;
  message?: Maybe<Scalars['ID']['output']>;
  occuredAt: Scalars['DateTime']['output'];
};

export enum JobEventType {
  Active = 'Active',
  Completed = 'Completed',
  Created = 'Created',
  Failed = 'Failed',
  Stalled = 'Stalled',
  Waiting = 'Waiting',
}

export enum JobType {
  GenerateBeats = 'GenerateBeats',
  GenerateStandardRoles = 'GenerateStandardRoles',
  IngestionAndMaskedCleanUp = 'IngestionAndMaskedCleanUp',
  ProcessInitiativesNotifications = 'ProcessInitiativesNotifications',
  ProcessSubmissionReminders = 'ProcessSubmissionReminders',
  PublishEvent = 'PublishEvent',
  RemoveRoles = 'RemoveRoles',
  RemoveUser = 'RemoveUser',
  RolePermissionSync = 'RolePermissionSync',
  RoleUsersSync = 'RoleUsersSync',
  RowLevelSecuritySync = 'RowLevelSecuritySync',
  StandardRolesRefresh = 'StandardRolesRefresh',
  TenantGroupsSync = 'TenantGroupsSync',
  TenantUsersSync = 'TenantUsersSync',
  UserGroupsSync = 'UserGroupsSync',
  UserSync = 'UserSync',
}

export type LifelineEvent = {
  __typename?: 'LifelineEvent';
  beats: Array<Beat>;
  clientDataFileFamilies: Array<ClientDataFileFamily>;
  clientDataFiles: Array<ClientDataFile>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  data?: Maybe<Scalars['JSON']['output']>;
  dataframes: Array<DataFrame>;
  datasetInstances: Array<DatasetInstance>;
  id: Scalars['ID']['output'];
  subject: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
};

export type LoginRoute = {
  __typename?: 'LoginRoute';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identityProvider: IdentityProvider;
  identityProviderId: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById?: Maybe<Scalars['String']['output']>;
};

export type LoginRouteInput = {
  domain: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  identityProvider: UpdateIdentityProviderInput;
};

export type MaintenanceSlot = {
  __typename?: 'MaintenanceSlot';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  endTime: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  startTime: Scalars['DateTime']['output'];
  tenants?: Maybe<Array<Tenant>>;
  title?: Maybe<Scalars['String']['output']>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
};

export type MaskedDataframeLocations = {
  __typename?: 'MaskedDataframeLocations';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dataframe: DataFrame;
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  locationType: DataframeLocationType;
  name: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
};

export enum MaskerState {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

export enum Month {
  April = 'April',
  August = 'August',
  December = 'December',
  February = 'February',
  January = 'January',
  July = 'July',
  June = 'June',
  March = 'March',
  May = 'May',
  November = 'November',
  October = 'October',
  September = 'September',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Change password of the requester */
  changeRequesterPassword: Scalars['Boolean']['output'];
  clearUrlMapCache: Scalars['Boolean']['output'];
  createLoginRoutes: Array<LoginRoute>;
  createMaintenanceSlot: MaintenanceSlot;
  createUrlMap: UrlMap;
  /** It will send a reset password link through email */
  forgotPassword: Scalars['Boolean']['output'];
  removeLoginRoute: Scalars['Boolean']['output'];
  removeUrlMap: Scalars['Boolean']['output'];
  updateLoginRoute: LoginRoute;
  updateMaintenanceSlot: MaintenanceSlot;
  updateUrlMap: UrlMap;
};

export type MutationChangeRequesterPasswordArgs = {
  newPassword: Scalars['String']['input'];
};

export type MutationCreateLoginRoutesArgs = {
  loginInputs: Array<LoginRouteInput>;
};

export type MutationCreateMaintenanceSlotArgs = {
  input: CreateMaintenanceSlotInput;
};

export type MutationCreateUrlMapArgs = {
  input: CreateUrlMapInput;
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationRemoveLoginRouteArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveUrlMapArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateLoginRouteArgs = {
  updateLoginRouteInput: UpdateLoginRouteInput;
};

export type MutationUpdateMaintenanceSlotArgs = {
  input: UpdateMaintenanceSlotInput;
};

export type MutationUpdateUrlMapArgs = {
  input: UpdateUrlMapInput;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  /** Description of the notification */
  description?: Maybe<Scalars['String']['output']>;
  /** uuid as primary key */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: NotificationType;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  users?: Maybe<Array<User>>;
};

export enum NotificationType {
  DataRefreshEmails = 'DataRefreshEmails',
}

export type OrganizationalUnit = {
  __typename?: 'OrganizationalUnit';
  /** Every entry in the directory has a distinguished name (DN). The DN is the name that uniquely identifies an entry in the directory. It is a sequence of relative distinguished names (RDN) connected by commas. An RDN is an attribute with an associated value in the form attribute=value; normally expressed in a UTF-8 string format. RDN attributes consist of commonName (CN), organizationalUnitName (OU) and so on. */
  distinguishedName: Scalars['String']['output'];
  /** Sub Organizational Units */
  subOu?: Maybe<Array<OrganizationalUnit>>;
};

export type OtherEmail = {
  __typename?: 'OtherEmail';
  email: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type OtherEmailInput = {
  email: Scalars['String']['input'];
};

export enum PeriodTypes {
  Calendar4Q = 'Calendar4Q',
  Quarter = 'Quarter',
}

export type PolicyRequest = {
  action: Scalars['String']['input'];
  attribute?: InputMaybe<Scalars['String']['input']>;
  dsmId?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  objectType: EnforcerType;
  resource?: InputMaybe<Scalars['String']['input']>;
  /** roles/<roleId> */
  subjectId?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  workbookGroupId?: InputMaybe<Scalars['String']['input']>;
  workbookId?: InputMaybe<Scalars['String']['input']>;
};

export type PolicyResponse = {
  __typename?: 'PolicyResponse';
  action: Scalars['String']['output'];
  attribute?: Maybe<Scalars['String']['output']>;
  dsmId?: Maybe<Scalars['String']['output']>;
  eft?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  /** roles/<roleId> */
  subjectId?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
  workbookGroupId?: Maybe<Scalars['String']['output']>;
  workbookId?: Maybe<Scalars['String']['output']>;
};

export enum Quarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export type Query = {
  __typename?: 'Query';
  /** Checks if a resources is granted for the logged in user */
  checkPolicies: Array<PolicyResponse>;
  loginRoute: LoginRoute;
  loginRoutes: Array<LoginRoute>;
  maintenanceSlot: MaintenanceSlot;
  maintenanceSlots: Array<MaintenanceSlot>;
  upcomingMaintenanceSlot: MaintenanceSlot;
  urlMap: UrlMap;
  urlMaps: Array<UrlMap>;
  /** Validate if requester is valid */
  validateUser: Scalars['Boolean']['output'];
};

export type QueryCheckPoliciesArgs = {
  policyInputs: Array<PolicyRequest>;
};

export type QueryLoginRouteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLoginRoutesArgs = {
  identityProvider?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMaintenanceSlotArgs = {
  id: Scalars['Float']['input'];
};

export type QueryUrlMapArgs = {
  id: Scalars['ID']['input'];
};

export type RecentTableauView = {
  __typename?: 'RecentTableauView';
  /** Unique id for this recent tableau view */
  id: Scalars['ID']['output'];
  /** Tableau site */
  tableauSite: TableauSite;
  /** Tableau view the user visited */
  tableauView: TableauView;
  /** The date the user visited the dashboard */
  viewedAt: Scalars['DateTime']['output'];
  /** User who visited the dashboard */
  viewedBy: User;
};

export type ReminderLog = {
  __typename?: 'ReminderLog';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  month?: Maybe<Month>;
  quarter?: Maybe<Quarter>;
  submitter: Submitter;
  submitterId: Scalars['String']['output'];
  type: ReminderTrigger;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export enum ReminderTrigger {
  Automated = 'Automated',
  Manual = 'Manual',
}

export enum RequestMethod {
  Both = 'Both',
  Get = 'Get',
  Post = 'Post',
}

export enum ResponseType {
  PermanentRedirect = 'PermanentRedirect',
  TemporaryRedirect = 'TemporaryRedirect',
}

export type RiskAudit = {
  __typename?: 'RiskAudit';
  arrivalDate?: Maybe<Scalars['DateTime']['output']>;
  auditId: Scalars['Float']['output'];
  biomeETLVersion?: Maybe<Scalars['String']['output']>;
  biomeEncounterId: Scalars['Float']['output'];
  biomeUpdateDt: Scalars['DateTime']['output'];
  careEntity: CareEntity;
  careEntityId: Scalars['String']['output'];
  careEntityName: Scalars['String']['output'];
  diagnosisPOACodeList?: Maybe<Scalars['String']['output']>;
  dischargeDate?: Maybe<Scalars['DateTime']['output']>;
  dischargeQuarter?: Maybe<Scalars['String']['output']>;
  dischargeYear?: Maybe<Scalars['String']['output']>;
  diseaseStateModule: DiseaseStateModule;
  diseaseStateModuleId: Scalars['String']['output'];
  encounterId?: Maybe<Scalars['String']['output']>;
  hospital?: Maybe<Scalars['String']['output']>;
  keyField?: Maybe<Scalars['String']['output']>;
  keyFieldValue?: Maybe<Scalars['String']['output']>;
  mrn?: Maybe<Scalars['String']['output']>;
  procedure?: Maybe<Scalars['String']['output']>;
  procedureDate?: Maybe<Scalars['DateTime']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  registryId?: Maybe<Scalars['Float']['output']>;
  reviewTriggerDescription?: Maybe<Scalars['String']['output']>;
  riskReview?: Maybe<Scalars['String']['output']>;
  riskValue?: Maybe<Scalars['String']['output']>;
  surgicalQuarter?: Maybe<Scalars['String']['output']>;
  surgicalYear?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
  trails: Array<RiskAuditTrail>;
  visitId?: Maybe<Scalars['Float']['output']>;
  visitTiming?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type RiskAuditTrail = {
  __typename?: 'RiskAuditTrail';
  auditTask?: Maybe<RiskAudit>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  diseaseStateModuleId: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  latestStudyOnRecord: Scalars['Boolean']['output'];
  notes: Scalars['String']['output'];
  /** Tracks the status of audit trail */
  status: RiskAuditTrailStatus;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export enum RiskAuditTrailStatus {
  ReviewAcceptedAndUpdated = 'ReviewAcceptedAndUpdated',
  ReviewRejected = 'ReviewRejected',
  ReviewRequired = 'ReviewRequired',
}

export type Role = {
  __typename?: 'Role';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  /** Indicates if policy deployment on tableau dashboard is in progress */
  deployingDashboardPolicies: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identityProviders: Array<IdentityProvider>;
  jobs?: Maybe<Array<Job>>;
  name: Scalars['String']['output'];
  tenant: Tenant;
  tenantId: Scalars['String']['output'];
  type: RoleType;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  users?: Maybe<Array<User>>;
};

export enum RoleType {
  BiomePk = 'BIOME_PK',
  BiomeUs = 'BIOME_US',
  Collaborator = 'COLLABORATOR',
  Custom = 'CUSTOM',
  SuperUser = 'SUPER_USER',
}

export enum StandardCompareBy {
  Cy = 'CY',
  Calendar4Q = 'Calendar4Q',
  Dynamic = 'Dynamic',
  Fy = 'FY',
  Quarter = 'Quarter',
}

export type StsAudit = {
  __typename?: 'StsAudit';
  arrivalDate?: Maybe<Scalars['DateTime']['output']>;
  auditId: Scalars['Float']['output'];
  biomeETLVersion?: Maybe<Scalars['String']['output']>;
  biomeEncounterId: Scalars['Float']['output'];
  biomeUpdateDt: Scalars['DateTime']['output'];
  careEntity: CareEntity;
  careEntityId: Scalars['String']['output'];
  careEntityName: Scalars['String']['output'];
  diagnosisPOACodeList?: Maybe<Scalars['String']['output']>;
  dischargeDate?: Maybe<Scalars['DateTime']['output']>;
  dischargeQuarter?: Maybe<Scalars['String']['output']>;
  dischargeYear?: Maybe<Scalars['String']['output']>;
  diseaseStateModule: DiseaseStateModule;
  diseaseStateModuleId: Scalars['String']['output'];
  encounterId?: Maybe<Scalars['String']['output']>;
  hospital?: Maybe<Scalars['String']['output']>;
  keyField?: Maybe<Scalars['String']['output']>;
  keyFieldValue?: Maybe<Scalars['String']['output']>;
  mrn?: Maybe<Scalars['String']['output']>;
  procedure?: Maybe<Scalars['String']['output']>;
  procedureDate?: Maybe<Scalars['DateTime']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  registryId?: Maybe<Scalars['Float']['output']>;
  reviewTriggerDescription?: Maybe<Scalars['String']['output']>;
  riskReview?: Maybe<Scalars['String']['output']>;
  riskValue?: Maybe<Scalars['String']['output']>;
  surgicalQuarter?: Maybe<Scalars['String']['output']>;
  surgicalYear?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
  trails: Array<StsAuditTrail>;
  visitId?: Maybe<Scalars['Float']['output']>;
  visitTiming?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type StsAuditTrail = {
  __typename?: 'StsAuditTrail';
  auditTask?: Maybe<StsAudit>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  diseaseStateModuleId: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  latestStudyOnRecord: Scalars['Boolean']['output'];
  notes: Scalars['String']['output'];
  /** Tracks the status of audit trail */
  status: RiskAuditTrailStatus;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type Submission = {
  __typename?: 'Submission';
  beats?: Maybe<Array<Beat>>;
  cadence: Scalars['String']['output'];
  careEntity: CareEntity;
  careEntityId: Scalars['String']['output'];
  clientDataFiles?: Maybe<Array<ClientDataFile>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  dataset: Dataset;
  datasetId: Scalars['Int']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mrnCrosswalkRequired: Scalars['Boolean']['output'];
  mrnHarvestRequired: Scalars['Boolean']['output'];
  submitNationalReport: Scalars['Boolean']['output'];
  submitters?: Maybe<Array<Submitter>>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
};

export type Submitter = {
  __typename?: 'Submitter';
  ccEmails?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  daysAfterDeadline: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  reminderLogs?: Maybe<Array<ReminderLog>>;
  sendReminders: Scalars['Boolean']['output'];
  submission: Submission;
  submissionId: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export enum SupportedApps {
  Ae = 'AE',
  Echo = 'ECHO',
  Oe = 'OE',
  Pm = 'PM',
  Riskaudit = 'RISKAUDIT',
}

export type TableauDsRefreshJob = {
  __typename?: 'TableauDSRefreshJob';
  /** batch number in which the refresh job was started */
  batchNo?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  refreshCompleteTime?: Maybe<Scalars['DateTime']['output']>;
  refreshStartTime?: Maybe<Scalars['DateTime']['output']>;
  /** The type of event that occurred */
  status: TableauDsRefreshJobStatus;
  tableauDataSource: TableauDataSource;
  tableauJobId?: Maybe<Scalars['String']['output']>;
  tableauSite?: Maybe<TableauSite>;
};

export enum TableauDsRefreshJobStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  New = 'New',
  Running = 'Running',
}

export type TableauDataSource = {
  __typename?: 'TableauDataSource';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  dataSourcesRefreshJobs?: Maybe<Array<TableauDsRefreshJob>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tableauSites?: Maybe<Array<TableauSite>>;
  /** Unique tableau id in the form of a URI aka contentUrl, e.g https://tableau/#/site/:site/views/Cath-FaceSheet/OEBleedingEvents, here Cath-FaceSheet is the view code */
  tableauUri: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  usesRls: Scalars['Boolean']['output'];
};

export type TableauExtract = {
  __typename?: 'TableauExtract';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  diseaseStateModules?: Maybe<Array<DiseaseStateModule>>;
  id: Scalars['ID']['output'];
  /** extract name - unique within a DSM. The output tde OR tds file will be generated with this name */
  name: Scalars['String']['output'];
  /** Reference uri to the sql file (for tde extract type) or tds file (for tds extract type) */
  type: TableauExtractType;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  /** Reference uri to the sql file (for tde extract type) or tds file (for tds extract type) */
  uri: Scalars['String']['output'];
};

export enum TableauExtractType {
  Hyper = 'HYPER',
  Tde = 'TDE',
  Tds = 'TDS',
}

export type TableauObjectOwner = {
  __typename?: 'TableauObjectOwner';
  id: Scalars['String']['output'];
};

export type TableauProject = {
  __typename?: 'TableauProject';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TableauReleaseParameter = {
  __typename?: 'TableauReleaseParameter';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  diseaseStateModules: Array<DiseaseStateModule>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: TableauReleaseParameterType;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export enum TableauReleaseParameterType {
  FreeText = 'FreeText',
  LovMulti = 'LovMulti',
  LovSingle = 'LovSingle',
}

export type TableauSite = {
  __typename?: 'TableauSite';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  dashboards?: Maybe<Array<TableauView>>;
  dataSources?: Maybe<Array<TableauDataSource>>;
  dataSourcesRefreshJobs?: Maybe<Array<TableauDsRefreshJob>>;
  id: Scalars['ID']['output'];
  inactiveWorkbooks?: Maybe<Array<Workbook>>;
  name: Scalars['String']['output'];
  recentTableauViews?: Maybe<Array<RecentTableauView>>;
  /** Unique tableau id in the form of a URI aka contentUrl, e.g https://tableau/#/site/ucla/views/Cath-FaceSheet/OEBleedingEvents, here ucla is the site code/uri */
  tableauUri: Scalars['String']['output'];
  tenant: Tenant;
  tenantId: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  /** biome site url for the client i.e. sutter.biome.io -> here sutter is the url */
  url: Scalars['String']['output'];
  workbooks?: Maybe<Array<Workbook>>;
};

export type TableauView = {
  __typename?: 'TableauView';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  recentTableauViews?: Maybe<Array<RecentTableauView>>;
  renderInMenu: Scalars['Boolean']['output'];
  sortOrder: Scalars['Float']['output'];
  tableauSites?: Maybe<Array<TableauSite>>;
  /** Unique tableau id in the form of a URI aka contentUrl, e.g https://tableau/#/site/:site/views/Cath-FaceSheet/OEBleedingEvents, here OEBleedingEvents is the view code */
  tableauUri: Scalars['String']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  workbook: Workbook;
  workbookId: Scalars['Float']['output'];
};

export enum Tags {
  Clinical = 'Clinical',
  Financial = 'Financial',
  Phi = 'Phi',
}

export type TavrAudit = {
  __typename?: 'TavrAudit';
  arrivalDate?: Maybe<Scalars['DateTime']['output']>;
  auditId: Scalars['Float']['output'];
  biomeETLVersion?: Maybe<Scalars['String']['output']>;
  biomeEncounterId: Scalars['Float']['output'];
  biomeUpdateDt: Scalars['DateTime']['output'];
  careEntity: CareEntity;
  careEntityId: Scalars['String']['output'];
  careEntityName: Scalars['String']['output'];
  diagnosisPOACodeList?: Maybe<Scalars['String']['output']>;
  dischargeDate?: Maybe<Scalars['DateTime']['output']>;
  dischargeQuarter?: Maybe<Scalars['String']['output']>;
  dischargeYear?: Maybe<Scalars['String']['output']>;
  diseaseStateModule: DiseaseStateModule;
  diseaseStateModuleId: Scalars['String']['output'];
  encounterId?: Maybe<Scalars['String']['output']>;
  hospital?: Maybe<Scalars['String']['output']>;
  keyField?: Maybe<Scalars['String']['output']>;
  keyFieldValue?: Maybe<Scalars['String']['output']>;
  mrn?: Maybe<Scalars['String']['output']>;
  procedure?: Maybe<Scalars['String']['output']>;
  procedureDate?: Maybe<Scalars['DateTime']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  registryId?: Maybe<Scalars['Float']['output']>;
  reviewTriggerDescription?: Maybe<Scalars['String']['output']>;
  riskReview?: Maybe<Scalars['String']['output']>;
  riskValue?: Maybe<Scalars['String']['output']>;
  surgicalQuarter?: Maybe<Scalars['String']['output']>;
  surgicalYear?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
  trails: Array<TavrAuditTrail>;
  visitId?: Maybe<Scalars['Float']['output']>;
  visitTiming?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type TavrAuditTrail = {
  __typename?: 'TavrAuditTrail';
  auditTask?: Maybe<TavrAudit>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  diseaseStateModuleId: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  latestStudyOnRecord: Scalars['Boolean']['output'];
  notes: Scalars['String']['output'];
  /** Tracks the status of audit trail */
  status: RiskAuditTrailStatus;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

/** Tenant/Client */
export type Tenant = {
  __typename?: 'Tenant';
  active: Scalars['Boolean']['output'];
  banner?: Maybe<File>;
  careEntities?: Maybe<Array<CareEntity>>;
  /** Schema name where audit, echo and cases data is available for tenant. If null, defaults to <tenantCode>_cases */
  casesSchema?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  contractedDisplayPHI: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  distributionLists?: Maybe<Array<DistributionList>>;
  documents?: Maybe<Array<File>>;
  encryptionKeys?: Maybe<Array<Scalars['String']['output']>>;
  /** uuid as primary key */
  id: Scalars['ID']['output'];
  identityProvider?: Maybe<IdentityProvider>;
  jobs?: Maybe<Array<Job>>;
  logo?: Maybe<File>;
  maintenanceSlots?: Maybe<Array<MaintenanceSlot>>;
  name: Scalars['String']['output'];
  organizationalUnit: Scalars['String']['output'];
  roles?: Maybe<Array<Role>>;
  submitsCostData: Scalars['Boolean']['output'];
  tableauSite?: Maybe<TableauSite>;
  tokens?: Maybe<Array<Token>>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  users?: Maybe<Array<User>>;
  workbookStandards?: Maybe<Array<WorkbookStandard>>;
};

export type TenantPolicyInput = {
  action: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  eft?: InputMaybe<Scalars['String']['input']>;
  objectType: Scalars['String']['input'];
  /** roles/<roleId> */
  subjectId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

/** JWT Token */
export type Token = {
  __typename?: 'Token';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  /** uuid as primary key */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  revokedAt: Scalars['DateTime']['output'];
  status: TokenStatus;
  subject: User;
  subjectId: Scalars['String']['output'];
  tenant: Tenant;
  tenantId: Scalars['String']['output'];
  /** JWT Token in hash format */
  token: Scalars['String']['output'];
  type: TokenType;
  updateAt: Scalars['DateTime']['output'];
};

export enum TokenStatus {
  Active = 'ACTIVE',
  Revoked = 'REVOKED',
}

export enum TokenType {
  Access = 'ACCESS',
  Email = 'EMAIL',
  Impersonate = 'IMPERSONATE',
  Longliving = 'LONGLIVING',
  PasswordChange = 'PASSWORD_CHANGE',
  Refresh = 'REFRESH',
}

export type UpdateCareEntityInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  aggregated?: InputMaybe<Scalars['Boolean']['input']>;
  alternateNames?: InputMaybe<Array<Scalars['String']['input']>>;
  anonymousCode?: InputMaybe<Scalars['String']['input']>;
  business?: InputMaybe<Scalars['String']['input']>;
  children?: InputMaybe<Array<UpdateCareEntityInput>>;
  city?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  datasets?: InputMaybe<Array<UpdateDatasetInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  diseaseStateModules?: InputMaybe<Array<UpdateDiseaseStateModuleInput>>;
  id: Scalars['ID']['input'];
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parents?: InputMaybe<Array<UpdateCareEntityInput>>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  submissions?: InputMaybe<Array<UpdateSubmissionInput>>;
  supportedApps?: InputMaybe<Array<SupportedApps>>;
  teaching?: InputMaybe<Scalars['Boolean']['input']>;
  tenants?: InputMaybe<Array<UpdateTenantInput>>;
  type?: InputMaybe<CareEntityType>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  usGeoRegion?: InputMaybe<UsGeoRegion>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClinicalProgramInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type UpdateDatasetInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isDropAndLoad?: InputMaybe<Scalars['Boolean']['input']>;
  isRegistry?: InputMaybe<Scalars['Boolean']['input']>;
  managingSociety?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type UpdateDiseaseStateModuleInput = {
  careEntities?: InputMaybe<Array<UpdateCareEntityInput>>;
  clinicalProgram?: InputMaybe<UpdateClinicalProgramInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  criticalDatasets?: InputMaybe<Array<UpdateDatasetInput>>;
  datasets?: InputMaybe<Array<UpdateDatasetInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  primaryDataset?: InputMaybe<UpdateDatasetInput>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  supportedApps?: InputMaybe<Array<SupportedApps>>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type UpdateDistributionListInput = {
  allowedFiles?: InputMaybe<Array<UpdateFileInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<UpdateTenantInput>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  users?: InputMaybe<Array<UpdateUserInput>>;
};

export type UpdateFileInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  distributionLists?: InputMaybe<Array<UpdateDistributionListInput>>;
  extension?: InputMaybe<Scalars['String']['input']>;
  fileLabel?: InputMaybe<UpdateFileLabelInput>;
  id: Scalars['ID']['input'];
  isLatest?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phi?: InputMaybe<Scalars['Boolean']['input']>;
  tenant?: InputMaybe<UpdateTenantInput>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  updateById?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<UpdateUserInput>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateFileLabelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  files?: InputMaybe<Array<FileInput>>;
  id: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type UpdateIdentityProviderInput = {
  certificate?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  defaultRoles?: InputMaybe<Array<UpdateRoleInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailAttribute?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  entryPoint?: InputMaybe<Scalars['String']['input']>;
  firstNameAttribute?: InputMaybe<Scalars['String']['input']>;
  forceAuthentication?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  identifierFormat?: InputMaybe<Scalars['String']['input']>;
  lastNameAttribute?: InputMaybe<Scalars['String']['input']>;
  logoutUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  provisionUsers?: InputMaybe<Scalars['Boolean']['input']>;
  tenant?: InputMaybe<UpdateTenantInput>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  updateUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateLoginRouteInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  identityProvider?: InputMaybe<UpdateIdentityProviderInput>;
};

export type UpdateMaintenanceSlotInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  tenants?: InputMaybe<Array<UpdateTenantInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type UpdateNotificationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NotificationType>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  users?: InputMaybe<Array<UpdateUserInput>>;
};

export type UpdateRoleInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tenant: UpdateTenantInput;
  type?: InputMaybe<RoleType>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  users?: InputMaybe<Array<UpdateUserInput>>;
};

export type UpdateSubmissionInput = {
  cadence?: InputMaybe<Scalars['String']['input']>;
  careEntity?: InputMaybe<UpdateCareEntityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  dataset?: InputMaybe<UpdateDatasetInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  mrnCrosswalkRequired?: InputMaybe<Scalars['Boolean']['input']>;
  mrnHarvestRequired?: InputMaybe<Scalars['Boolean']['input']>;
  submitNationalReport?: InputMaybe<Scalars['Boolean']['input']>;
  submitters?: InputMaybe<Array<UpdateSubmitterInput>>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type UpdateSubmitterInput = {
  ccEmails?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  daysAfterDeadline?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  sendReminders?: InputMaybe<Scalars['Boolean']['input']>;
  submission?: InputMaybe<UpdateSubmissionInput>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  user?: InputMaybe<UpdateUserInput>;
};

export type UpdateTenantInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  banner?: InputMaybe<FileInput>;
  careEntities?: InputMaybe<Array<UpdateCareEntityInput>>;
  casesSchema?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  contractedDisplayPHI?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  documents?: InputMaybe<Array<FileInput>>;
  encryptionKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  logo?: InputMaybe<FileInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationalUnit?: InputMaybe<Scalars['String']['input']>;
  submitsCostData?: InputMaybe<Scalars['Boolean']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type UpdateUrlMapInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UpdateUserInput>;
  destination?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  method?: InputMaybe<RequestMethod>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  responseType?: InputMaybe<ResponseType>;
  source?: InputMaybe<Scalars['String']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
};

export type UpdateUserInput = {
  adGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  allowedFiles?: InputMaybe<Array<UpdateFileInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<CreateUserInput>;
  distributionLists?: InputMaybe<Array<UpdateDistributionListInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Time when the user was enabled. This is used to keep certain items on the UI disabled until this change is synced to Azure AD. */
  enabledTime?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<CreateBookmarkInput>;
  id: Scalars['ID']['input'];
  lastLogin?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Array<UpdateNotificationInput>>;
  organizationalUnit?: InputMaybe<Scalars['String']['input']>;
  otherEmails?: InputMaybe<Array<OtherEmailInput>>;
  passwordNeverExpires?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UpdateRoleInput>>;
  root?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<UserStatus>;
  submitsData?: InputMaybe<Scalars['Boolean']['input']>;
  tenant?: InputMaybe<UpdateTenantInput>;
  tenantPolicies?: InputMaybe<Array<TenantPolicyInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  twoFactor?: InputMaybe<Scalars['Boolean']['input']>;
  updateAt?: InputMaybe<Scalars['DateTime']['input']>;
  updateBy?: InputMaybe<UpdateUserInput>;
  /** Unique username */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UrlMap = {
  __typename?: 'UrlMap';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  destination: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  method: RequestMethod;
  priority: Scalars['Int']['output'];
  responseType: ResponseType;
  source: Scalars['String']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
};

export enum UsGeoRegion {
  Canada = 'Canada',
  EastSouthCentral = 'EastSouthCentral',
  GreatLakes = 'GreatLakes',
  GreatPlains = 'GreatPlains',
  MiddleAtlantic = 'MiddleAtlantic',
  Mountain = 'Mountain',
  NewEngland = 'NewEngland',
  Pacific = 'Pacific',
  SouthAtlantic = 'SouthAtlantic',
  WestSouthCentral = 'WestSouthCentral',
}

export type User = {
  __typename?: 'User';
  adGroups?: Maybe<Array<Scalars['String']['output']>>;
  bookmarks?: Maybe<Array<Bookmark>>;
  clientDataFiles?: Maybe<ClientDataFile>;
  /** Creation Datetime */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  distributionLists?: Maybe<Array<DistributionList>>;
  email: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  /** Time when the user was enabled. This is used to keep certain items on the UI disabled until this change is synced to Azure AD. */
  enabledTime?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<FileDistributionList>>;
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  homepage?: Maybe<Bookmark>;
  /** Auto increment ID */
  id: Scalars['ID']['output'];
  identityStore?: Maybe<Scalars['String']['output']>;
  jobs?: Maybe<Array<Job>>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  notifications?: Maybe<Array<Notification>>;
  organizationalUnit: Scalars['String']['output'];
  otherEmails?: Maybe<Array<OtherEmail>>;
  passwordNeverExpires: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  recentTableauViews?: Maybe<Array<RecentTableauView>>;
  roles?: Maybe<Array<Role>>;
  root: Scalars['Boolean']['output'];
  /** Tracks the status of user account activation */
  status?: Maybe<UserStatus>;
  submitsData: Scalars['Boolean']['output'];
  submitters?: Maybe<Array<Submitter>>;
  tenant: Tenant;
  tenantId: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  tokens?: Maybe<Array<Token>>;
  twoFactor?: Maybe<Scalars['Boolean']['output']>;
  /** Modification Datetime */
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  /** Unique username */
  username: Scalars['String']['output'];
};

export type UserAccessSnapshot = {
  id: Scalars['String']['input'];
  roles: Array<Scalars['String']['input']>;
  root: Scalars['Boolean']['input'];
  submitsData: Scalars['Boolean']['input'];
  tenants: Array<Scalars['String']['input']>;
};

export enum UserStatus {
  Activated = 'ACTIVATED',
  Initiated = 'INITIATED',
  Pending = 'PENDING',
}

export enum VisibilityType {
  Default = 'DEFAULT',
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE',
}

export type Workbook = {
  __typename?: 'Workbook';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  diseaseStateModule?: Maybe<DiseaseStateModule>;
  diseaseStateModuleId: Scalars['Int']['output'];
  hideTabs: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  inactiveTableauSites?: Maybe<Array<TableauSite>>;
  measureIds?: Maybe<Array<Scalars['Int']['output']>>;
  name: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
  sortOrder: Scalars['Int']['output'];
  tableauSites?: Maybe<Array<TableauSite>>;
  /** Unique tableau id in the form of a URI aka contentUrl, e.g https://tableau/#/site/:site/views/Cath-FaceSheet/OEBleedingEvents, here Cath-FaceSheet is the view code */
  tableauUri: Scalars['String']['output'];
  tableauViews?: Maybe<Array<TableauView>>;
  tags?: Maybe<Array<Tags>>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  workbookGroup?: Maybe<WorkbookGroup>;
  workbookGroupId?: Maybe<Scalars['Int']['output']>;
  workbookStandards?: Maybe<Array<WorkbookStandard>>;
};

export type WorkbookGroup = {
  __typename?: 'WorkbookGroup';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  diseaseStateModule: DiseaseStateModule;
  diseaseStateModuleId: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  renderAsLink: Scalars['Boolean']['output'];
  renderInMenu: Scalars['Boolean']['output'];
  sortOrder: Scalars['Float']['output'];
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  workbookStandards?: Maybe<Array<WorkbookStandard>>;
  workbooks?: Maybe<Array<Workbook>>;
};

export type WorkbookStandard = {
  __typename?: 'WorkbookStandard';
  active: Scalars['Boolean']['output'];
  adminCondition?: Maybe<Scalars['String']['output']>;
  benchmarkType: BenchmarkType;
  compareBy: StandardCompareBy;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endQuarter?: Maybe<Scalars['String']['output']>;
  /** Auto generated ID */
  id: Scalars['ID']['output'];
  masterTableName: Scalars['String']['output'];
  periodFieldName: Scalars['String']['output'];
  periodType: PeriodTypes;
  /** # of recent data points to be selected from the time dimension selected, 0 for all */
  periods: Scalars['Int']['output'];
  startQuarter?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  updateAt: Scalars['DateTime']['output'];
  updateBy?: Maybe<User>;
  updateById: Scalars['String']['output'];
  workbookGroups?: Maybe<Array<WorkbookGroup>>;
  workbooks?: Maybe<Array<Workbook>>;
};

export type GetMaintenanceSlotsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMaintenanceSlotsQuery = {
  __typename?: 'Query';
  maintenanceSlots: Array<{
    __typename?: 'MaintenanceSlot';
    id: number;
    title?: string | null;
    description?: string | null;
    startTime: any;
    endTime: any;
    enabled: boolean;
  }>;
};

export type GetMaintenanceSlotQueryVariables = Exact<{
  maintenanceSlotId: Scalars['Float']['input'];
}>;

export type GetMaintenanceSlotQuery = {
  __typename?: 'Query';
  maintenanceSlot: {
    __typename?: 'MaintenanceSlot';
    id: number;
    title?: string | null;
    description?: string | null;
    startTime: any;
    endTime: any;
    enabled: boolean;
  };
};

export type GetUpcomingMaintenanceSlotQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUpcomingMaintenanceSlotQuery = {
  __typename?: 'Query';
  upcomingMaintenanceSlot: {
    __typename?: 'MaintenanceSlot';
    id: number;
    title?: string | null;
    description?: string | null;
    startTime: any;
    endTime: any;
    enabled: boolean;
  };
};

export type CreateMaintenanceSlotMutationVariables = Exact<{
  input: CreateMaintenanceSlotInput;
}>;

export type CreateMaintenanceSlotMutation = {
  __typename?: 'Mutation';
  createMaintenanceSlot: {
    __typename?: 'MaintenanceSlot';
    id: number;
    title?: string | null;
    description?: string | null;
    startTime: any;
    endTime: any;
    enabled: boolean;
  };
};

export type UpdateMaintenanceSlotMutationVariables = Exact<{
  input: UpdateMaintenanceSlotInput;
}>;

export type UpdateMaintenanceSlotMutation = {
  __typename?: 'Mutation';
  updateMaintenanceSlot: {
    __typename?: 'MaintenanceSlot';
    id: number;
    title?: string | null;
    description?: string | null;
    startTime: any;
    endTime: any;
    enabled: boolean;
  };
};

export type GetUrlMapsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUrlMapsQuery = {
  __typename?: 'Query';
  urlMaps: Array<{
    __typename?: 'UrlMap';
    id: string;
    source: string;
    destination: string;
    priority: number;
    enabled: boolean;
    method: RequestMethod;
    responseType: ResponseType;
  }>;
};

export type GetUrlMapQueryVariables = Exact<{
  urlMapId: Scalars['ID']['input'];
}>;

export type GetUrlMapQuery = {
  __typename?: 'Query';
  urlMap: {
    __typename?: 'UrlMap';
    id: string;
    source: string;
    destination: string;
    priority: number;
    enabled: boolean;
    method: RequestMethod;
    responseType: ResponseType;
  };
};

export type CreateUrlMapMutationVariables = Exact<{
  input: CreateUrlMapInput;
}>;

export type CreateUrlMapMutation = {
  __typename?: 'Mutation';
  createUrlMap: {
    __typename?: 'UrlMap';
    id: string;
    source: string;
    destination: string;
    priority: number;
    enabled: boolean;
    method: RequestMethod;
    responseType: ResponseType;
  };
};

export type UpdateUrlMapMutationVariables = Exact<{
  input: UpdateUrlMapInput;
}>;

export type UpdateUrlMapMutation = {
  __typename?: 'Mutation';
  updateUrlMap: {
    __typename?: 'UrlMap';
    id: string;
    source: string;
    destination: string;
    priority: number;
    enabled: boolean;
    method: RequestMethod;
    responseType: ResponseType;
  };
};

export type RemoveUrlMapMutationVariables = Exact<{
  removeUrlMapId: Scalars['ID']['input'];
}>;

export type RemoveUrlMapMutation = {
  __typename?: 'Mutation';
  removeUrlMap: boolean;
};

export type ClearUrlMapCacheMutationVariables = Exact<{ [key: string]: never }>;

export type ClearUrlMapCacheMutation = {
  __typename?: 'Mutation';
  clearUrlMapCache: boolean;
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword: boolean;
};

export type ChangeRequesterPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
}>;

export type ChangeRequesterPasswordMutation = {
  __typename?: 'Mutation';
  changeRequesterPassword: boolean;
};

export type ValidateUserQueryVariables = Exact<{ [key: string]: never }>;

export type ValidateUserQuery = { __typename?: 'Query'; validateUser: boolean };

export const GetMaintenanceSlotsDocument = gql`
  query getMaintenanceSlots {
    maintenanceSlots {
      id
      title
      description
      startTime
      endTime
      enabled
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetMaintenanceSlotsGQL extends Apollo.Query<
  GetMaintenanceSlotsQuery,
  GetMaintenanceSlotsQueryVariables
> {
  document = GetMaintenanceSlotsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetMaintenanceSlotDocument = gql`
  query getMaintenanceSlot($maintenanceSlotId: Float!) {
    maintenanceSlot(id: $maintenanceSlotId) {
      id
      title
      description
      startTime
      endTime
      enabled
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetMaintenanceSlotGQL extends Apollo.Query<
  GetMaintenanceSlotQuery,
  GetMaintenanceSlotQueryVariables
> {
  document = GetMaintenanceSlotDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUpcomingMaintenanceSlotDocument = gql`
  query getUpcomingMaintenanceSlot {
    upcomingMaintenanceSlot {
      id
      title
      description
      startTime
      endTime
      enabled
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUpcomingMaintenanceSlotGQL extends Apollo.Query<
  GetUpcomingMaintenanceSlotQuery,
  GetUpcomingMaintenanceSlotQueryVariables
> {
  document = GetUpcomingMaintenanceSlotDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateMaintenanceSlotDocument = gql`
  mutation createMaintenanceSlot($input: CreateMaintenanceSlotInput!) {
    createMaintenanceSlot(input: $input) {
      id
      title
      description
      startTime
      endTime
      enabled
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateMaintenanceSlotGQL extends Apollo.Mutation<
  CreateMaintenanceSlotMutation,
  CreateMaintenanceSlotMutationVariables
> {
  document = CreateMaintenanceSlotDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateMaintenanceSlotDocument = gql`
  mutation updateMaintenanceSlot($input: UpdateMaintenanceSlotInput!) {
    updateMaintenanceSlot(input: $input) {
      id
      title
      description
      startTime
      endTime
      enabled
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateMaintenanceSlotGQL extends Apollo.Mutation<
  UpdateMaintenanceSlotMutation,
  UpdateMaintenanceSlotMutationVariables
> {
  document = UpdateMaintenanceSlotDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUrlMapsDocument = gql`
  query getUrlMaps {
    urlMaps {
      id
      source
      destination
      priority
      enabled
      method
      responseType
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUrlMapsGQL extends Apollo.Query<
  GetUrlMapsQuery,
  GetUrlMapsQueryVariables
> {
  document = GetUrlMapsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUrlMapDocument = gql`
  query getUrlMap($urlMapId: ID!) {
    urlMap(id: $urlMapId) {
      id
      source
      destination
      priority
      enabled
      method
      responseType
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUrlMapGQL extends Apollo.Query<
  GetUrlMapQuery,
  GetUrlMapQueryVariables
> {
  document = GetUrlMapDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateUrlMapDocument = gql`
  mutation createUrlMap($input: CreateUrlMapInput!) {
    createUrlMap(input: $input) {
      id
      source
      destination
      priority
      enabled
      method
      responseType
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateUrlMapGQL extends Apollo.Mutation<
  CreateUrlMapMutation,
  CreateUrlMapMutationVariables
> {
  document = CreateUrlMapDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUrlMapDocument = gql`
  mutation updateUrlMap($input: UpdateUrlMapInput!) {
    updateUrlMap(input: $input) {
      id
      source
      destination
      priority
      enabled
      method
      responseType
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUrlMapGQL extends Apollo.Mutation<
  UpdateUrlMapMutation,
  UpdateUrlMapMutationVariables
> {
  document = UpdateUrlMapDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveUrlMapDocument = gql`
  mutation removeUrlMap($removeUrlMapId: ID!) {
    removeUrlMap(id: $removeUrlMapId)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveUrlMapGQL extends Apollo.Mutation<
  RemoveUrlMapMutation,
  RemoveUrlMapMutationVariables
> {
  document = RemoveUrlMapDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ClearUrlMapCacheDocument = gql`
  mutation clearUrlMapCache {
    clearUrlMapCache
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ClearUrlMapCacheGQL extends Apollo.Mutation<
  ClearUrlMapCacheMutation,
  ClearUrlMapCacheMutationVariables
> {
  document = ClearUrlMapCacheDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordGQL extends Apollo.Mutation<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
> {
  document = ForgotPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChangeRequesterPasswordDocument = gql`
  mutation changeRequesterPassword($newPassword: String!) {
    changeRequesterPassword(newPassword: $newPassword)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChangeRequesterPasswordGQL extends Apollo.Mutation<
  ChangeRequesterPasswordMutation,
  ChangeRequesterPasswordMutationVariables
> {
  document = ChangeRequesterPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ValidateUserDocument = gql`
  query validateUser {
    validateUser
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ValidateUserGQL extends Apollo.Query<
  ValidateUserQuery,
  ValidateUserQueryVariables
> {
  document = ValidateUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
