import { createAction, props } from '@ngrx/store';
import { LocalLoginId } from './local-login-ids.reducer';

export const addLoginId = createAction(
  '[Login] Add New Login Id',
  props<{ username: string }>()
);
export const updateLoginIdsSuccess = createAction(
  '[Login Effects] Update Login Id Success',
  props<{ localLoginIds: LocalLoginId[] }>()
);
export const removeLoginId = createAction(
  '[Login] Remove A Login Id',
  props<{ username: string }>()
);

export const getLoginIds = createAction(
  '[Login] Get Saved Login Ids from localStorage'
);
export const getLoginIdsSuccess = createAction(
  '[Login Effects] Get Saved Login Ids from LocalStorage Success',
  props<{ localLoginIds: LocalLoginId[] }>()
);
