import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { EffectsModule } from '@ngrx/effects';
import { LocalLoginIdsEffects } from './state/local-login-ids/local-login-ids.effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './state';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BusyInterceptor } from '@busy-bar/lib/busy.interceptor';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'config/environments/environment';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { SharedLibModule } from '@shared/public-api';

const uri = `${environment.apiHost.bifrost}graphql`;
export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({ uri }),
    cache: new InMemoryCache(),
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BusyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideAnimations(),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    importProvidersFrom(
      StoreModule.forRoot(reducers),
      EffectsModule.forRoot([LocalLoginIdsEffects]),
      StoreRouterConnectingModule.forRoot({
        stateKey: 'router',
      }),
      ApolloModule,
      SharedLibModule
    ),
  ],
};
