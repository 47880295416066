import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly _authenticator: AuthenticationService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this._authenticator.accessToken;
    let request: HttpRequest<any>;
    if (accessToken && !this.isLoginRequest(req.url)) {
      request = this._addTokenHeader(req, accessToken);
    } else {
      request = req.clone();
    }
    return next.handle(request).pipe(
      tap((response: any) => {
        if (this._isGqlAuthError(response)) {
          throw new HttpErrorResponse({
            error: 'Token expired',
            status: 401,
          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !this.isLoginRequest(error.url)) {
          this._authenticator.expireSession();
        }
        return throwError(() => error);
      })
    );
  }
  private _addTokenHeader(request: HttpRequest<any>, accessToken: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: true,
    });
  }
  private _isGqlAuthError(response: HttpResponse<any>) {
    return (
      response.body?.errors?.[0]?.extensions?.originalError?.statusCode === 401
    );
  }
  isLoginRequest(url: string | null): boolean {
    return !!url && url.endsWith('/token');
  }
}
