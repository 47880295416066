<mat-card
  class="main-container"
  *ngIf="_resetPasswordService.resetPasswordStatus() as resetPasswordStatus"
>
  <mat-card-header>
    <mat-card-title class="header-logo">
      <img src="/assets/images/logo.png" alt="biome-logo" />
    </mat-card-title>
    <mat-card-title
      ><h3 class="title">
        {{
          resetPasswordStatus.type === "success"
            ? "Password Updated Successfully"
            : "Change Password for " + username
        }}
      </h3></mat-card-title
    >
  </mat-card-header>
  <mat-card-content>
    <br />
    <form
      [formGroup]="changePasswordForm"
      (ngSubmit)="onSubmit()"
      #ngChangePasswordForm="ngForm"
      *ngIf="resetPasswordStatus.type !== 'success'"
    >
      <mat-form-field class="form-field">
        <mat-label>New password</mat-label>
        <input matInput formControlName="newPassword" type="password" />
        <mat-error
          *ngIf="newPassword?.touched && newPassword.hasError('required')"
          >Password is required</mat-error
        >
      </mat-form-field>
      <div class="validation">
        <!-- password validation rules -->
        <div
          class="validation"
          *ngIf="newPassword.dirty && newPassword.hasError"
        >
          <span class="validation-status">
            <mat-icon
              class="material-symbols-outlined icon"
              [ngClass]="minLengthValid ? 'success' : 'error'"
              >{{ minLengthValid ? "check_circle" : "cancel" }}</mat-icon
            >
            Must be at least 8 characters long
          </span>
          <span class="validation-status">
            <mat-icon
              class="material-symbols-outlined icon"
              [ngClass]="requiresDigitValid ? 'success' : 'error'"
              >{{ requiresDigitValid ? "check_circle" : "cancel" }}</mat-icon
            >
            Must contain at least 1 digit
          </span>
          <span class="validation-status">
            <mat-icon
              class="material-symbols-outlined icon"
              [ngClass]="requiresUppercaseValid ? 'success' : 'error'"
              >{{
                requiresUppercaseValid ? "check_circle" : "cancel"
              }}</mat-icon
            >
            Must contain at least 1 uppercase character
          </span>
          <span class="validation-status">
            <mat-icon
              class="material-symbols-outlined icon"
              [ngClass]="requiresLowercaseValid ? 'success' : 'error'"
              >{{
                requiresLowercaseValid ? "check_circle" : "cancel"
              }}</mat-icon
            >
            Must contain at least 1 lowercase character
          </span>
          <span class="validation-status">
            <mat-icon
              class="material-symbols-outlined icon"
              [ngClass]="requiresSpecialCharsValid ? 'success' : 'error'"
              >{{
                requiresSpecialCharsValid ? "check_circle" : "cancel"
              }}</mat-icon
            >
            Must contain at least 1 special character
          </span>
        </div>
      </div>
      <mat-form-field class="form-field">
        <mat-label>Confirm password</mat-label>
        <input matInput formControlName="confirmPassword" type="password" />
        <mat-error
          *ngIf="
            confirmPassword?.touched && confirmPassword.hasError('required')
          "
          >Password is required</mat-error
        >
      </mat-form-field>
      <div class="validation">
        <!-- password validation rules -->
        <div class="validation" *ngIf="confirmPassword?.dirty && requiredValid">
          <span class="validation-status">
            <mat-icon
              class="material-symbols-outlined icon"
              [ngClass]="
                !confirmPassword.hasError('mismatch') ? 'success' : 'error'
              "
              >{{
                !confirmPassword.hasError("mismatch")
                  ? "check_circle"
                  : "cancel"
              }}</mat-icon
            >
            Password should match
          </span>
        </div>
      </div>
    </form>

    <div
      [ngClass]="{
        'auth-error': resetPasswordStatus.type === 'error',
        'auth-success': resetPasswordStatus.type === 'success'
      }"
    >
      {{
        resetPasswordStatus.message.includes("UnwillingToPerformError")
          ? "Please make sure:"
          : resetPasswordStatus.message
      }}
      <ol
        *ngIf="resetPasswordStatus.message.includes('UnwillingToPerformError')"
      >
        <li>This password is not one of the previously used five passwords.</li>
        <li>The username is not a part of this password.</li>
        <li>This password is not similar to previously used passwords.</li>
        <li>This is not an easily guessable password.</li>
      </ol>
    </div>
    <br />
    <button
      *ngIf="_resetPasswordService.resetPasswordStatus().type !== 'success'"
      mat-raised-button
      data-ba-id="changePassword"
      color="primary"
      (click)="onSubmit()"
    >
      Continue
    </button>
    <button
      *ngIf="_resetPasswordService.resetPasswordStatus().type === 'success'"
      mat-raised-button
      data-ba-id="continueTologin"
      color="primary"
      [routerLink]="['/login']"
    >
      <mat-icon class="material-symbols-outlined">login</mat-icon>
      <div class="login-text-container">
        <span class="login-text">Continue To Login </span>
      </div>
    </button>
    <div class="info-box">
      If you are having trouble updating your password please reach out to
      <a href="mailto:support@biome.io">support&#64;biome.io</a>.
    </div>
  </mat-card-content>
</mat-card>
<ba-support-button />
