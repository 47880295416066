import { createReducer, on } from '@ngrx/store';
import * as PermissionActions from './permission.actions';
import { PolicyResponse } from '@chiron/app/shared/graphql';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const permissionFeatureKey = 'permissions';
export interface PermissionState extends EntityState<PolicyResponse> {
  loaded: boolean;
  error?: string;
}
export const adapter: EntityAdapter<PolicyResponse> =
  createEntityAdapter<PolicyResponse>();
export const initialState: PermissionState = adapter.getInitialState({
  loaded: false,
});

export const reducer = createReducer(
  initialState,
  on(PermissionActions.clearPermissions, () => ({
    ...initialState,
  })),
  on(PermissionActions.loadPermissionsSuccess, (state, action) =>
    adapter.setAll(action.permissions, { ...state, loaded: true })
  ),
  on(PermissionActions.loadPermissionsFailure, (state, action) => ({
    ...state,
    loaded: true,
    error: action.error,
  }))
);
