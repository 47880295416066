import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { select, Store } from '@ngrx/store';
import { catchError, of, tap } from 'rxjs';
import { SubSink } from 'subsink';
import {
  LocalLoginIdActions,
  LocalLoginIdSelectors,
} from '../../state/store-types';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SupportButtonComponent } from '@shared/lib/components/support-button/support-button.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'ba-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SupportButtonComponent,
    RouterModule,
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();
  readonly busy = signal<boolean>(false);
  readonly authError = signal<string>('');
  readonly username = this._fb.control<string>('', [
    Validators.required,
    Validators.email,
  ]);
  readonly savedLoginIds$ = this._store.pipe(
    select(LocalLoginIdSelectors.selectAll)
  );
  constructor(
    private readonly _fb: NonNullableFormBuilder,
    private readonly _authenticator: AuthenticationService,
    private readonly _store: Store
  ) {}

  ngOnInit(): void {
    this._authenticator.logout();
    this._store.dispatch(LocalLoginIdActions.getLoginIds());
  }
  removeLoginId(username: string) {
    this._store.dispatch(
      LocalLoginIdActions.removeLoginId({
        username,
      })
    );
  }
  selectLoginId(username: string) {
    this.busy.set(true);
    username && this.discover(username);
  }
  discover(username: string) {
    if (!username) {
      return;
    }
    this._subs.sink = this._authenticator
      .discover(username)
      .pipe(
        tap(() => this.authError.set('')),
        catchError((e) => {
          this.authError.set(
            e.error?.message ||
              'The username you entered did not match our records. Please check the entered username and try again.'
          );
          return of(null);
        }),
        tap(() => this.busy.set(false))
      )
      .subscribe();
  }
  onSubmit() {
    this.username.markAsTouched();
    if (this.username.valid) {
      this.busy.set(true);
      this._store.dispatch(
        LocalLoginIdActions.addLoginId({
          username: this.username.value,
        })
      );
      this.discover(this.username.value);
    }
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
