import { ColumnState, FilterState } from '@ag-grid-community/core';
import { createAction, props } from '@ngrx/store';
import { LocalAppState } from './local-app-state.reducer';
import { RowSpacingType } from '@shared/lib/components/toolbar-component/row-settings-toolbar.component';

export const saveViewType = createAction(
  '[AG Grid State Persist Service] Save Grid State',
  props<{ viewType: string; id: string }>()
);
export const saveViewTypeSuccess = createAction(
  '[AG Grid State Persist Effect] Save Grid State Success',
  props<{ viewType: string; id: string }>()
);
export const saveGridColumnState = createAction(
  '[AG Grid Column State Directive] Save Grid Column State',
  props<{ gridColumnState: ColumnState[]; gridId: string }>()
);
export const saveGridColumnStateSuccess = createAction(
  '[Save Grid Column State Effect] Save Grid Column State Success',
  props<{ gridColumnState: ColumnState[]; gridId: string }>()
);
export const saveGridFilterState = createAction(
  '[AG Grid Filter State Directive] Save Grid Filter State',
  props<{ gridId: string; state: FilterState }>()
);
export const saveGridFilterStateSuccess = createAction(
  '[Save Grid Filter State Effect] Save Grid Filter State Success',
  props<{
    gridId: string;
    state: FilterState;
  }>()
);
export const loadLocalAppState = createAction(
  '[App Module Oninit] Get localAppState From localStorage'
);
export const loadLocalAppStateSuccess = createAction(
  '[Load localAppState Effect] Get localAppState From localStorage Success',
  props<{ localAppState: LocalAppState }>()
);
export const hideWelcomeBanner = createAction(
  '[Opportunities - Hide Welcome Banner] Hide Welcome Banner'
);
export const hideWelcomeBannerSuccess = createAction(
  '[Hide Welcome Banner Effect] Hide Welcome Banner Success'
);
export const hideHelpTip = createAction(
  '[Opportunities - Hide Help Tip] Hide Help Tip'
);
export const hideHelpTipSuccess = createAction(
  '[Hide Help Tip Effect] Hide Help Tip Success'
);
export const hideDisclaimer = createAction(
  '[Disclaimer Actions] Hide Disclaimer'
);
export const hideDisclaimerSuccess = createAction(
  '[Hide Disclaimer Effect] Hide Disclaimer Success'
);
export const hidePatientListDisclaimer = createAction(
  '[Patient list Disclaimer Hide Checkbox] Hide Patient List Disclaimer'
);
export const hidePatientListDisclaimerSuccess = createAction(
  '[Hide Patient List Disclaimer Effect] Hide Patient List Disclaimer Success'
);
export const hideEchoCasesDisclaimer = createAction(
  '[Echo Cases Disclaimer Hide Checkbox] Hide Echo Cases Disclaimer'
);
export const hideEchoCasesDisclaimerSuccess = createAction(
  '[Hide Echo Cases Disclaimer Effect] Hide Echo Cases Disclaimer Success'
);
export const hideRiskAuditsDisclaimer = createAction(
  '[Risk Audit Disclaimer Hide Checkbox] Hide Risk Audits Disclaimer'
);
export const hideRiskAuditsDisclaimerSuccess = createAction(
  '[Hide Risk Audit Disclaimer Effect] Hide Risk Audit Disclaimer Success'
);
export const hideDashboardUndoAlert = createAction(
  '[Dashboard Details Page Undo/Redo action] Hide Dashboard Undo Alert'
);
export const hideDashboardUndoAlertSuccess = createAction(
  '[Hide Dashboard Undo Alert Effect] Hide Dashboard Undo Alert Success'
);
export const clearSessionPreferences = createAction(
  '[Authentication Service] Clear Session Preferences'
);
export const clearSessionPreferencesSuccess = createAction(
  '[Clear Session Preferences Effect] Clear Session Preferences Success'
);
export const saveAccessChangeComment = createAction(
  '[Change Confirmation Modal] Save Access Change Comment ',
  props<{ comment: string }>()
);
export const saveAccessChangeCommentSuccess = createAction(
  '[Change Confirmation Modal] Save Access Change Comment Success',
  props<{ comment: string }>()
);
export const hideMaintenanceNotice = createAction(
  '[Homepage] Hide Maintenance Notice'
);
export const hideMaintenanceNoticeSuccess = createAction(
  '[Hide Maintenance Notice Effect] Hide Maintenance Notice Success'
);
export const saveGridRowSpacing = createAction(
  '[Grid List] Save Grid List Row Spacing',
  props<{ gridId: string; size: RowSpacingType }>()
);
export const saveGridRowSpacingSuccess = createAction(
  '[Grid List] Save Grid List Row Spacing Success',
  props<{ gridId: string; size: RowSpacingType }>()
);
export const saveGridRowStriping = createAction(
  '[Grid List] Save Grid List Row Striping',
  props<{ gridId: string; striping: boolean }>()
);
export const saveGridRowStripingSuccess = createAction(
  '[Grid List] Save Grid List Row Striping Success',
  props<{ gridId: string; striping: boolean }>()
);
export const hideTableauLicenseInfoPanel = createAction(
  '[Admin - Hide Tableau License Info Panel] Hide Tableau License Info Panel'
);
export const hideTableauLicensePanelInfoSuccess = createAction(
  '[Hide Tableau License Info Effect] Hide Tableau License Info Success'
);
