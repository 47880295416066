import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { ResetPasswordService } from '../services/reset-password.service';

export const canResetPasswordGuard: CanActivateFn = () => {
  const resetPasswordService = inject(ResetPasswordService);
  const router = inject(Router);
  return resetPasswordService.validateUser().pipe(
    map((res) => {
      if (!res) {
        router.navigate(['']);
        return false;
      }
      return true;
    })
  );
};
