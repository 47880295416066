import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocalLoginId } from './local-login-ids.reducer';

const getLocalLoginIdsState =
  createFeatureSelector<LocalLoginId[]>('localLoginIds');

export const selectAll = createSelector(
  getLocalLoginIdsState,
  (state) => state
);
