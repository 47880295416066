import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const KEY = 'baSupportButtonHidden';

@Injectable({ providedIn: 'root' })
export class SupportButtonService {
  private readonly _subject = new BehaviorSubject<boolean>(
    localStorage.getItem(KEY) === 'true'
  );
  readonly supportButtonHidden$ = this._subject.asObservable();
  toggle() {
    const hidden = localStorage.getItem(KEY) === 'true';
    localStorage.setItem(KEY, String(!hidden));
    this._subject.next(!hidden);
  }
}
