import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as MaintenanceSlotActions from './maintenance-slot.actions';
import { GetMaintenanceSlotQuery } from 'graphql/bifrost/generated';

export const featureKey = 'maintenanceSlots';
export type MaintenanceSlot = GetMaintenanceSlotQuery['maintenanceSlot'];

export interface MaintenanceSlotsState extends EntityState<MaintenanceSlot> {
  upcomingSlot: MaintenanceSlot | null;
}
export const adapter: EntityAdapter<MaintenanceSlot> =
  createEntityAdapter<MaintenanceSlot>();
export const initialState: MaintenanceSlotsState = adapter.getInitialState({
  upcomingSlot: null,
});
export const reducer = createReducer(
  initialState,
  on(MaintenanceSlotActions.loadMaintenanceSlotsSuccess, (state, action) =>
    adapter.setAll(action.maintenanceSlots, state)
  ),
  on(MaintenanceSlotActions.loadMaintenanceSlotSuccess, (state, action) =>
    adapter.addOne(action.maintenanceSlot, state)
  ),
  on(MaintenanceSlotActions.createMaintenanceSlotSuccess, (state, action) =>
    adapter.addOne(action.maintenanceSlot, state)
  ),
  on(MaintenanceSlotActions.updateMaintenanceSlotSuccess, (state, action) =>
    adapter.updateOne(action.maintenanceSlot, state)
  ),
  on(
    MaintenanceSlotActions.loadUpcomingMaintenanceSlotSuccess,
    (state, action) => ({
      ...state,
      upcomingSlot: action.maintenanceSlot,
    })
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
